import { React, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import callFetch from "../../../../../helpers/callFetch";
import flatpickr from "flatpickr";
import SoftSnackbar from "components/SoftSnackbar";
import Cookies from 'js-cookie';
import dateFormat from "dateformat";
// @mui material components
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
function LeadDetails(props) {
    const { t } = useTranslation();
    const [refesh3, setRefesh3] = useState(0);
    const [currentStage, setCurrentStage] = useState(0);
    const [successSB, setSuccessSB] = useState(false);
    const [errorSB, setErrorSB] = useState(false);
    const [menu, setMenu] = useState(null);
    const openMenu = (event) => setMenu(event.currentTarget);
    const closeMenu = () => setMenu(null);
    const closeErrorSB = () => setErrorSB(false);

    function updateStage(stage) {
        const formData = new FormData();
        formData.stage = stage;
        formData.id = props.id;
        callFetch("update-lifecycle-stage", "POST", formData, setError).then((res) => {
            setCurrentStage(res?.stage);
            setSuccessSB(true);
        });
    }

    useEffect(() => {
        setCurrentStage(props.stage);
    }, [props.stage]);

    const renderSuccessSB = (
        <SoftSnackbar
            color="success"
            icon="check"
            title={t('Success')}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={successSB}
            onClose={() => setSuccessSB(false)}
            close={() => setSuccessSB(false)}
            bgSuccess
        />
    );

    const renderErrorSB = (
        <SoftSnackbar
            color="error"
            icon="info"
            title={t('Nachricht, Notiz oder Info dürfen nicht leer sein')}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={errorSB}
            onClose={() => closeErrorSB(false)}
            close={() => closeErrorSB(false)}
            bgSuccess
        />
    );

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();


    return <>
        <div className="d-flex" style={{ padding: '0px 0px' }}>
            <div className="d-flex justify-content-start" style={{ paddingTop: '20px', width: '50%' }}>
                <SoftTypography variant="caption" fontWeight="bold" color="text" style={{ fontSize: '15px' }}>
                    Sales Pipeline:
                </SoftTypography>

            </div>
            <div className="d-flex justify-content-end" style={{ paddingTop: '20px', width: '50%', flaot: 'right' }}>
                <SoftTypography variant="caption" className="stage-action-title" fontWeight="normal" color="text" style={{ fontSize: '15px' }}>
                    Lyfescyle Stage:
                </SoftTypography>

                <SoftButton className="ms-3" variant={menu ? "contained" : "outlined"} style={{ border: 'none', position: 'relative', top: '-12px', color: '#005498', padding: '0px' }} onClick={openMenu}>
                    {t(
                        currentStage == 1 ? 'Neukunde'
                        : currentStage == 2 ? 'Kontaktiert'
                        : currentStage == 3 ? 'Interessiert'
                        : currentStage == 4 ? 'Qualifiziert'
                        : currentStage == 5 ? 'Angebot'
                        : currentStage == 6 ? 'Gewinn'
                        : currentStage == 7 ? 'Verlust'
                            : 'Neukunde'
                    )}&nbsp;
                    <Icon>keyboard_arrow_down</Icon>
                </SoftButton>
                <Menu
                    anchorEl={menu}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    transformOrigin={{ vertical: "top", horizontal: "left" }}
                    open={Boolean(menu)}
                    onClose={closeMenu}
                    keepMounted
                >
                    <>
                        <MenuItem onClick={() => { closeMenu(); updateStage('2') }}>{t('Kontaktiert')}</MenuItem>
                        <MenuItem onClick={() => { closeMenu(); updateStage('3') }}>{t('Interessiert')}</MenuItem>
                        <MenuItem onClick={() => { closeMenu(); updateStage('4') }}>{t('Qualifiziert')}</MenuItem>
                        <MenuItem onClick={() => { closeMenu(); updateStage('6') }}>{t('Gewinn')}</MenuItem>
                        <MenuItem onClick={() => { closeMenu(); updateStage('7') }}>{t('Verlust')}</MenuItem>
                    </>
                </Menu>
            </div>
        </div>
        {currentStage == 1 ? <div className="d-flex lead-details" style={{ padding: '0px 0px' }}>
            <a className="shape-right-arrow stage-done" href="javascript:void(0);">
                <i className="fa fa-check"></i> &nbsp;&nbsp;  Neukunde </a>
            <a className="shape-left-right-arrow stage-ongoing" onClick={() => updateStage('2')} href="javascript:void(0);">
                Kontaktiert</a>
            <a className="shape-left-right-arrow stage-not-done" onClick={() => updateStage('3')} href="javascript:void(0);">
                Interessiert</a>

            <a className="shape-left-right-arrow stage-not-done" onClick={() => updateStage('4')} href="javascript:void(0);">
                Qualifiziert</a>

            <a className="shape-left-right-arrow stage-not-done" onClick={() => updateStage('5')} href="javascript:void(0);">
                Angebot</a>
            <a className="shape-left-arrow stage-not-done" onClick={() => updateStage('6')} href="javascript:void(0);">
                Gewinn</a>
        </div> : currentStage == 2 ? <div className="d-flex lead-details" style={{ padding: '0px 0px' }}>
            <a className="shape-right-arrow stage-done" href="javascript:void(0);">
                <i className="fa fa-check"></i> &nbsp;&nbsp;  Neukunde </a>

            <a className="shape-left-right-arrow stage-done" onClick={() => updateStage('2')} href="javascript:void(0);">
                <i className="fa fa-check"></i> &nbsp;&nbsp; Kontaktiert</a>
            <a className="shape-left-right-arrow stage-ongoing" onClick={() => updateStage('3')} href="javascript:void(0);">
                Interessiert</a>

            <a className="shape-left-right-arrow stage-not-done" onClick={() => updateStage('4')} href="javascript:void(0);">
                Qualifiziert</a>

            <a className="shape-left-right-arrow stage-not-done" onClick={() => updateStage('5')} href="javascript:void(0);">
                Angebot</a>
            <a className="shape-left-arrow stage-not-done" onClick={() => updateStage('6')} href="javascript:void(0);">
                Gewinn</a>
        </div> : currentStage == 3 ? <div className="d-flex lead-details" style={{ padding: '0px 0px' }}>
            <a className="shape-right-arrow stage-done" href="javascript:void(0);">
                <i className="fa fa-check"></i> &nbsp;&nbsp;  Neukunde </a>

            <a className="shape-left-right-arrow stage-done" onClick={() => updateStage('2')} href="javascript:void(0);">
                <i className="fa fa-check"></i> &nbsp;&nbsp; Kontaktiert</a>
            <a className="shape-left-right-arrow stage-done" onClick={() => updateStage('3')} href="javascript:void(0);">
                <i className="fa fa-check"></i> &nbsp;&nbsp; Interessiert</a>

            <a className="shape-left-right-arrow stage-ongoing" onClick={() => updateStage('4')} href="javascript:void(0);">
                Qualifiziert</a>

            <a className="shape-left-right-arrow stage-not-done" onClick={() => updateStage('5')} href="javascript:void(0);">
                Angebot</a>
            <a className="shape-left-arrow stage-not-done" onClick={() => updateStage('6')} href="javascript:void(0);">
                Gewinn</a>
        </div> : currentStage == 4 ? <div className="d-flex lead-details" style={{ padding: '0px 0px' }}>
            <a className="shape-right-arrow stage-done" href="javascript:void(0);">
                <i className="fa fa-check"></i> &nbsp;&nbsp;  Neukunde </a>

            <a className="shape-left-right-arrow stage-done" onClick={() => updateStage('2')} href="javascript:void(0);">
                <i className="fa fa-check"></i> &nbsp;&nbsp; Kontaktiert</a>
            <a className="shape-left-right-arrow stage-done" onClick={() => updateStage('3')} href="javascript:void(0);">
                <i className="fa fa-check"></i> &nbsp;&nbsp; Interessiert</a>

            <a className="shape-left-right-arrow stage-done" onClick={() => updateStage('4')} href="javascript:void(0);">
                <i className="fa fa-check"></i> &nbsp;&nbsp; Qualifiziert</a>

            <a className="shape-left-right-arrow stage-ongoing" onClick={() => updateStage('5')} href="javascript:void(0);">
                Angebot</a>
            <a className="shape-left-arrow stage-not-done" onClick={() => updateStage('6')} href="javascript:void(0);">
                Gewinn</a>
        </div> : currentStage == 5 ? <div className="d-flex lead-details" style={{ padding: '0px 0px' }}>
            <a className="shape-right-arrow stage-done" href="javascript:void(0);">
                <i className="fa fa-check"></i> &nbsp;&nbsp;  Neukunde </a>

            <a className="shape-left-right-arrow stage-done" onClick={() => updateStage('2')} href="javascript:void(0);">
                <i className="fa fa-check"></i> &nbsp;&nbsp; Kontaktiert</a>
            <a className="shape-left-right-arrow stage-done" onClick={() => updateStage('3')} href="javascript:void(0);">
                <i className="fa fa-check"></i> &nbsp;&nbsp; Interessiert</a>

            <a className="shape-left-right-arrow stage-done" onClick={() => updateStage('4')} href="javascript:void(0);">
                <i className="fa fa-check"></i> &nbsp;&nbsp; Qualifiziert</a>

            <a className="shape-left-right-arrow stage-done" onClick={() => updateStage('5')} href="javascript:void(0);">
                <i className="fa fa-check"></i> &nbsp;&nbsp;  Angebot</a>
            <a className="shape-left-arrow stage-ongoing" onClick={() => updateStage('6')} href="javascript:void(0);">
                Gewinn</a>
        </div> : currentStage == 6 || currentStage == 7 ? <div className="d-flex lead-details" style={{ padding: '0px 0px' }}>
            <a className="shape-right-arrow stage-done" href="javascript:void(0);">
                <i className="fa fa-check"></i> &nbsp;&nbsp;  Neukunde </a>

            <a className="shape-left-right-arrow stage-done" onClick={() => updateStage('2')} href="javascript:void(0);">
                <i className="fa fa-check"></i> &nbsp;&nbsp; Kontaktiert</a>
            <a className="shape-left-right-arrow stage-done" onClick={() => updateStage('3')} href="javascript:void(0);">
                <i className="fa fa-check"></i> &nbsp;&nbsp; Interessiert</a>

            <a className="shape-left-right-arrow stage-done" onClick={() => updateStage('4')} href="javascript:void(0);">
                <i className="fa fa-check"></i> &nbsp;&nbsp; Qualifiziert</a>

            <a className="shape-left-right-arrow stage-done" onClick={() => updateStage('5')} href="javascript:void(0);">
                <i className="fa fa-check"></i> &nbsp;&nbsp; Angebot</a>
            <a className={"shape-left-arrow stage-done " + (currentStage == 7 ? 'stage-lost' : '')} onClick={() => updateStage('6')} href="javascript:void(0);">
                <i className="fa fa-check"></i> &nbsp;&nbsp; {currentStage == 6 ? 'Gewinn' : 'Verlust'}</a>
        </div> : ''}
        <button className="d-none" id="callcenterRefesh3" onClick={() => { setRefesh3(refesh3 + 1); }}>refesh3</button>
        {renderErrorSB}
        {renderSuccessSB}
    </>;
}

export default LeadDetails;
