import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Bar } from 'react-chartjs-2';
import { useForm } from "react-hook-form";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import callFetch from "../../../../../../helpers/callFetch";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const EstimatedProductionTwo = (props) => {
  let params = useParams();
  
  const [rows, setRows] = useState({
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [],
  });

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    reset,
    formState: { errors },
} = useForm();

  const [kwhPreis, setKwhPreis] = useState(0); // State to manage the input value
  const [refresh, setRefresh] = useState(0); // State to manage the input value

  const updateKwhPreis = (preis) => {
    var formData = new FormData();
    formData.preis = preis;
    formData.id = (props?.projectid ? props?.projectid : params.id);
    callFetch("update-kwh-preis", "POST", formData).then((res) => {
      setRefresh(refresh + 1)
     })
  }

  useEffect(() => { 
    callFetch("estimated-production-consumption/" + (props?.projectid ? props?.projectid : params.id), "GET", []).then((res) => { 
      const resData = res?.response_data_two; 
      console.log(res?.response_data_two);
      setKwhPreis(res?.kwh_preis);
      setValue('kwh_preis', res?.kwh_preis);
      setRows({
        labels: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
        datasets: [
          {
            label: 'Vor Solar',
            data: resData.vor_solar,
            backgroundColor: 'rgba(72, 187, 120)',
            borderRadius: 4,
            borderSkipped: false,
            maxBarThickness: 8,
          },
          {
            label: 'Mit Solar',
            data: resData.mit_solar,
            backgroundColor: 'rgba(32, 141, 252)',
            borderRadius: 4,
            borderSkipped: false,
            maxBarThickness: 8,
          },
          {
            label: 'Solar und Speicher',
            data: resData.solar_und_speicher,
            backgroundColor: 'rgba(229, 62, 62)',
            borderRadius: 4,
            borderSkipped: false,
            maxBarThickness: 8,
          },
        ],
      });
    }).catch(error => {
      console.error('Error fetching data:', error);
    });
  }, [props?.projectid, params.id, refresh]);

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: "", // Placeholder text for title
      },
    },
    scales: {
      x: {
        categoryPercentage: 0.6,
        barPercentage: 0.6,
        grid: {
          drawBorder: false,
          display: true,
          drawOnChartArea: true,
          drawTicks: false,
          borderDash: [5, 5],
        },
        ticks: {
          display: true,
          padding: 10,
          color: "#9ca2b7",
          font: {
            size: 12,
            family: "Open Sans",
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          drawBorder: false,
          display: true,
          drawOnChartArea: true,
          drawTicks: false,
          borderDash: [5, 5],
        },
        ticks: {
          display: true,
          padding: 10,
          color: "#9ca2b7",
          font: {
            size: 12,
            family: "Open Sans",
          },
        },
      },
    },
  };

  return (
    <div style={{ position: "relative", width: "100%", height: "500px" }}>
      {/* Input field overlay */}
      <div style={{ position: "absolute", top: "-5px", left: "50%", transform: "translateX(-50%)", zIndex: 10 }}>
        <p>
          Production and Consumption Data
          <input
            type="number"
            className="form-control form-control-sm"
            placeholder="Enter kWh Preis"
            defaultValue={kwhPreis}
            {...register('kwh_preis')}
            onChange={(e) => updateKwhPreis(e.target.value)}
            style={{ display: "inline-block", marginLeft: "10px", width: "120px" }}
          />
        </p>
      </div>
      {/* Chart */}
      <Bar data={rows} options={options} />
    </div>
  );
};

export default EstimatedProductionTwo;
