import React, { useRef, useState } from "react";

const VideoComponent = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);

  return (
    <div className="video-container eec">
      <video
        style={{ width: '100%' }}
        ref={videoRef}
        className="video"
        poster="/assets/img/video.png" // Replace with your poster image URL
        autoPlay // Enable autoplay
          // Show controls
        loop
        onPlay={() => setIsPlaying(true)} // Update state when video starts
      >
        <source src="/assets/videos/eecvideo.mp4" type="video/mp4" /> 
        Your browser does not support the video tag.
      </video> 
    </div>
  );
};

export default VideoComponent;
