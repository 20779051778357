import { React, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftEditor from "components/SoftEditor";

function ComisioningModal(props) {
    let params = useParams();
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [comisioning, setComisioning] = useState('0');
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [ready, setReady] = useState(false);

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        unregister,
        formState: { errors },
    } = useForm();


    useEffect(() => {

        if (!props?.projectid) return;
        if (!props?.id) return;

        //console.log('ComisioningModal')
        //console.log(props.type)

        setReady(false);

        if (props.type == 'dc') {
            callFetch((props?.getdataUrl ? props?.getdataUrl : "dc_abnahmeprotokoll/") + props.projectid + "/edit" + (props?.reklamationId ? "?reclamation_id=" + props?.reklamationId : ''), "GET", []).then((res) => {
                setComisioning(res?.data?.dcabname?.comisioning_status);
                setValue('description', res?.data?.dcabname?.comisioning_status_description && res?.data?.dcabname?.comisioning_status_description != 'undefined' ? res?.data?.dcabname?.comisioning_status_description : '');
                setReady(true);
            })
        } else if (props.type == 'ac') {
            callFetch((props?.getdataUrl ? props?.getdataUrl : "ac_abnahmeprotokoll/") + props.projectid + "/edit" + (props?.reklamationId ? "?reclamation_id=" + props?.reklamationId : ''), "GET", []).then((res) => {
                setComisioning(res?.data?.dcabname?.comisioning_status);
                setValue('description', res?.data?.dcabname?.comisioning_status_description && res?.data?.dcabname?.comisioning_status_description != 'undefined' ? res?.data?.dcabname?.comisioning_status_description : '');
                setReady(true);
            })
        }
    }, [props?.id, props?.projectid, props?.type, props?.reklamationId, props?.getdataUrl]);

    const onSubmit = (formData) => {
        if (!props?.id) return;
        if (!comisioning) return;

        formData.acdcid = props.id;
        formData.type = props.type;
        formData.abnahme = (props?.abnahme ? props?.abnahme : 'project');
        formData.commissioning = comisioning;

        setSaving(true);
        callFetch("comisioning-status", "POST", formData, setError).then((res) => {

            document.getElementById('ComisioningModalClose').click();
            setSaving(false);

            if (props?.setRefresh) {
                props.setRefresh((Math.random() * 1000) + 1000);
            }

            if (comisioning == "2") {
                if (props.type == 'ac') {
                    document.getElementById('openACAbnahmeTicket').click();
                } else {
                    document.getElementById('openDCAbnahmeTicket').click();
                }

                document.getElementById('setTicketDefaultAssignorderprocessingId').click();

            }

            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    /*
    const descriptionStatus = (status) => {
        if (status == '2') {
            document.getElementById('commissioning').style.display = 'none';
            setComisioning(1);
        } else {
            document.getElementById('commissioning').style.display = 'block';
            setComisioning(2);
        }
    }
    */

    return (
        <>
            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <input type="hidden" {...register('id')} value={props.projectid} />

                <a href="#" data-bs-toggle="modal" id="ComisioningModalOpen" data-bs-target="#ComisioningModal" className="btn btn-outline-secondary px-5 d-none">ComisioningModal</a>
                <div className="modal fade" id="ComisioningModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{t(props?.type == 'dc' ? 'Dc Commissioning Successfully Done' : 'Ac Commissioning Successfully Done')}?</h5>
                                <button
                                    type="button"
                                    className="btn-close text-dark"
                                    id="ComisioningModalClose"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="d-flex align-items-center justify-content-between mt-2" >
                                        <div className="d-flex align-items-center">
                                            <label className="mt-2" >{t('Yes')} &nbsp;&nbsp;&nbsp;</label>
                                            <div className="form-check d-inline-block">
                                                <input
                                                    type="radio"
                                                    {...register("commissioning")}
                                                    className="form-check-input me-md-3"
                                                    value={1}
                                                    onClick={() => setComisioning(1)}
                                                    checked={comisioning == '1' ? true : false}
                                                />
                                            </div>

                                            <label className="mt-2" >{t('No')} &nbsp;&nbsp;&nbsp;</label>
                                            <div className="form-check d-inline-block">
                                                <input
                                                    type="radio"
                                                    {...register("commissioning")}
                                                    className="form-check-input me-md-7"
                                                    value={2}
                                                    onClick={() => setComisioning(2)}
                                                    checked={comisioning == '2' ? true : false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="invalid-feedback">{errors.erdungsanschluss && errors.erdungsanschluss.message}</div>
                                </div>

                                {/*comisioning == '2' ?
                                    <div id="commissioning">
                                        <label>{t('Description')}</label>
                                        <textarea className="form-control" {...register('description')}>

                                        </textarea>
                                    </div>
                                    : <div id="commissioning" style={{ display: 'none' }}>
                                        <label>{t('Description')}</label>
                                        <textarea className="form-control" {...register('description')}>

                                        </textarea></div>*/}
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-outline-dark btn-start mt-2"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    {t("Cancel")}
                                </button>

                                {comisioning && ready && props?.id ? (
                                    <>
                                        {!saving && (
                                            <button
                                                type="submit"
                                                className="btn"
                                                style={{
                                                    background: '#d63384',
                                                    color: '#ffffff'
                                                }}
                                            >
                                                {t(comisioning == '1' ? 'Abnahmeprotokol erstellen' : 'Abnahmeprotokol erstellen')}
                                            </button>
                                        )}
                                        {saving && (
                                            <button type="submit" className="btn btn-disabled" disabled>
                                                {t('Abnahmeprotokol erstellen ...')}
                                            </button>
                                        )}
                                    </>
                                ) : (
                                    <button
                                        type="button"
                                        className="btn"
                                        style={{
                                            background: '#d63384',
                                            color: '#ffffff'
                                        }}
                                        disabled
                                    >
                                        {t(comisioning == '1' ? 'Abnahmeprotokol erstellen' : 'Abnahmeprotokol erstellen')}
                                    </button>
                                )}
                            </div>

                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}

export default ComisioningModal;
