import { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import callFetch from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import deleteAlert from "../../../helpers/deleteAlert";
import Cookies from 'js-cookie';
import dateFormat from "dateformat";
import { NumericFormat } from 'react-number-format';
import SoftBadge from 'components/SoftBadge';
import SoftBox from "components/SoftBox";
import SoftProgress from "components/SoftProgress";
import Beschaffungsliste from "pages/purchase/Beschaffungsliste/Index"

const OpenInvoiceIndexTable = (props) => {
    const { t } = useTranslation();
    const [tabledata, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const [loading, setLoading] = useState(false);
    const [projectId, setProjectId] = useState(0);

    var typingTimer;
    const [searchKey, setSearchKey] = useState("");

    const status = (data, progress = false, status = false, payment_option = '') => {
        var totalPercentage = 0;

        for (let [key, value] of Object.entries(data)) {
            if (data[key]?.product_details) {
                totalPercentage += data[key]?.amountPerchantage;
            }
        }

        return (
            <>
                {progress ? (
                    <SoftBox width="8rem">
                        <SoftProgress variant="gradient" value={totalPercentage} color={totalPercentage <= payment_option.split(',')?.[1] ? 'info' : totalPercentage == 100 && payment_option.split(',')?.[0] == 100 ? 'success' : totalPercentage <= payment_option.split(',')?.[0] ? 'warning' : 'success'} />
                    </SoftBox>
                ) : ''}

                {status ? (
                    <>

                        {payment_option.split(',').map((value, index) => (
                            <SoftBadge
                                className="me-2"
                                variant="contained"
                                size="xs"
                                badgeContent={value + '%'}
                                color={
                                    data.some(item => item.amountPerchantage === parseInt(value, 10))
                                        ? 'success'
                                        : 'dark'
                                }
                                container
                            />
                        ))}

                        {/*
                        <SoftBadge
                            variant="contained"
                            size="xs"
                            badgeContent={70 + '%'}
                            color={totalPercentage == 70 ? 'success' : data.length != 1 && totalPercentage == 100 ? 'success' : 'dark'}
                            container
                        />

                        <SoftBadge
                            className="mx-2"
                            variant="contained"
                            size="xs"
                            badgeContent={30 + '%'}
                            color={totalPercentage == 30 ? 'success' : data.length != 1 && totalPercentage == 100 ? 'success' : 'dark'}
                            container
                        />

                        <SoftBadge
                            variant="contained"
                            size="xs"
                            badgeContent={100 + '%'}
                            color={data.length == 1 && totalPercentage == 100 ? 'success' : 'dark'}
                            container
                        />
                        */}
                    </>
                ) : ''}
            </>
        );
    }

    const tableHeadings = [
        {
            name: t('Projektnr.'),
            width: '8rem',
            selector: row => (
                <NavLink to={'/projects/' + row?.id} className={"text-primary font-weight-bold"}>{row?.id}</NavLink>
            ),
            sortable: true,
        },
        {
            name: t('Kunde'),
            selector: row => (row?.kunde),
        },
        {
            name: t('VKB'),
            selector: row => (row?.salse_person?.name),
        },
        {
            name: t('Lieferdatum'),
            width: '12rem',
            selector: row => <span style={{ cursor: 'pointer' }} data-bs-toggle="modal" data-bs-target="#BeschaffungslisteDetails" onClick={() => { setProjectId(row?.id) }} className={"text-primary"}>{row?.beschaffung?.id ? 'PO-' + row?.beschaffung?.id + ' - ' + dateFormat(row?.beschaffung?.item?.lieferdatum, "dd.mm.yyyy") : ''}</span>,
        },
        {
            name: t('Lieferdatum Speicher'),
            width: '12rem',
            selector: row => (row?.lieferdatum_speicher ? dateFormat(row?.lieferdatum_speicher, "dd.mm.yyyy") : row?.purchase?.siefertermin_speicher ? dateFormat(row?.purchase?.siefertermin_speicher, "dd.mm.yyyy") : '--'),
        },
        {
            name: t('Lieferdatum Wechselrichter'),
            width: '12rem',
            selector: row => (row?.lieferdatum_wechselrichter ? dateFormat(row?.lieferdatum_wechselrichter, "dd.mm.yyyy") : row?.purchase?.liefertermin_wechselrichter ? dateFormat(row?.purchase?.liefertermin_wechselrichter, "dd.mm.yyyy") : '--'),
        },
        {
            name: t('Auftrag Gesamt'),
            width: '11rem',
            selector: row => (
                <>
                    <NumericFormat
                        value={Number(row?.quations?.order?.netto)}
                        displayType="text"
                        thousandSeparator={"."}
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale
                        suffix={row?.quations?.order?.currency == 'EUR' ? ' € ' : ' $ '}
                    />
                </>
            ),
        },
        {
            name: t('Status'),
            width: '18rem',
            selector: row => status(row?.quations?.order?.invoices ? row?.quations?.order?.invoices : [], true, false, row?.quations?.payment_option),
        },
        {
            name: t('Erstellte Rechnungen'),
            width: '11rem',
            selector: row => status(row?.quations?.order?.invoices ? row?.quations?.order?.invoices : [], false, true, row?.quations?.payment_option),
        },
    ];

    useEffect(() => {
        setTableData([]);
        setLoading(true);
        callFetch("open-invoice?page=" + pageNumber + '&status=' + props?.status + '&search=' + searchKey, "GET", []).then((res) => {
            setTableData(res.data);
            //console.log(res.data)
            setLoading(false);
        });
        //setTableData(data)
    }, [pageNumber, refresh, props?.status, searchKey]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <>
                <br />

                <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {tabledata.from} {t('to')} {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>
                <nav className="float-md-end me-2">
                    <ul className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                            >
                                &#60;
                            </button>
                        </li>

                        {tabledata?.links.map((page) => {
                            //const className = (parseInt(page.label) === currentPage ? "page-item active" : "page-item");
                            const className = (page.active === true ? "page-item active" : "page-item");
                            return (
                                <li key={page.label} className={className + ' ' + (parseInt(page.label) || page.label == '...' ? '' : 'd-none')}>
                                    <button
                                        className="page-link"
                                        onClick={page.label != '...' ? handlePageNumber : ''}
                                        value={page.label}
                                        disabled={page.label == '...' ? true : false}
                                    >
                                        {page.label}
                                    </button>
                                </li>
                            );
                        })}

                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                            >
                                &#62;
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="clearfix"></div>
            </>
        );
    };

    return <>
        <DataTable
            columns={tableHeadings}
            data={tabledata?.data}
            noDataComponent={loading ? t('Loading...') : t('There are no records to display')}
            className='data-table'
            pagination
            highlightOnHover
            paginationComponentOptions={{ noRowsPerPage: true }}
            paginationServer
            paginationTotalRows={tabledata?.total}
            onChangePage={handlePageChange}
            paginationComponent={BootyPagination}
            subHeader
            subHeaderComponent={
                <>
                    <input
                        type="text"
                        placeholder={t("Search...")}
                        className=' form-control w-sm-50 w-md-25 w-lg-15 ms-1 mt-1'
                        defaultValue={searchKey}
                        /*
                        onChange={(e)=> {
                            setSearchKey(e.target.value);
                        }}
                        */
                        onKeyUp={(e) => {
                            clearTimeout(typingTimer);
                            typingTimer = setTimeout(() => {
                                setTableData([]);
                                setSearchKey(e.target.value);
                            }, 1500);
                        }}
                        onKeyDown={(e) => {
                            clearTimeout(typingTimer);
                        }}
                    />
                </>
            }
        />


        <div className="modal fade" id="BeschaffungslisteDetails" tabindex="-1" role="dialog" aria-labelledby="BeschaffungslisteDetailsLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h6 className="modal-title" id="BeschaffungslisteDetailsLabel" style={{ fontWeight: 'bold' }}>{t('')}</h6>
                        <button
                            type="button"
                            id="BeschaffungslisteDetailsClose"
                            className="btn-close text-dark"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body tb-ac-cs">
                        <Beschaffungsliste projectid={projectId} />
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default OpenInvoiceIndexTable