import { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import flatpickr from "flatpickr";

import Cookies from 'js-cookie';

import PDF from "./PDF";
import SignaturePad from 'react-signature-pad-wrapper'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import callFetch from "helpers/callFetch";

const DCabnahmeSign = (props, { project_id }) => {
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [showPadClient, setShowPadClient] = useState(true);
    const [showPad, setShowPad] = useState(true);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const signaturePadRef = useRef(null);
    const signaturePadRefClient = useRef(null);
    const [data, setData] = useState([]);
    const [playAnimation, setPlayAnimation] = useState(true);
    const {
        reset, resetField, register, handleSubmit, setError, setValue, getValues,
        formState: { errors },
    } = useForm();
    useEffect(() => {
        flatpickr(".flat-pickr");

        if(props?.signFrom == 'reklamation'){
            var url = "dc_reklmation_abhnahmeprotokoll/" + props.project_id + "/edit?reclamation_id="+props.reklamationId
        }else{
            var url = "dc_abnahmeprotokoll/" + props.project_id + "/edit"
        }

        callFetch(url, "GET", []).then((res) => {
            setData(res.data)
            if ((res.data?.sign?.officer_sign && res.data?.sign?.officer_sign !== null) || (res.data?.sign?.client_sign && res.data?.sign?.client_sign !== null)) {
                signaturePadRef.current.fromDataURL(res.data?.sign?.officer_sign);
                signaturePadRefClient.current.fromDataURL(res.data?.sign?.client_sign);
            }
        })

    }, [refresh, props.project_id, props?.signFrom]);

    useEffect(() => {

    }, [refresh])

    const hideSign = () => {
        setShowPad(true);
    }

    const hideSignClient = () => {
        setShowPadClient(true);
    }

    useEffect(() => { // useEffect hook
        if (playAnimation) {
            //document.body.classList.add('loading');
        }
        setTimeout(() => { // simulate a delay
            setPlayAnimation(false)
            //document.body.classList.remove('loading');
        }, 10000);
    }, [refresh]);


    const onSubmit = (formData) => {

        if (props?.id || data?.dcabname?.id) {
            setSaving(true);
            /*
            if (signaturePadRef.current.isEmpty() && signaturePadRefClient.current.isEmpty()) {
                //alert('Sign please');
                setRefresh(refresh + 1);
                return false;
            }
            */
            formData.sign = signaturePadRef.current.toDataURL('image/png');
            formData.client_sign = signaturePadRefClient.current.toDataURL('image/png')
            formData.id = (props?.id ? props?.id : data?.dcabname?.id);

            if(props?.signFrom == 'reklamation'){
                var url = 'reclamation/dcsign';
            }else{
                var url = 'dcsign';
            }

            callFetch(url, "POST", formData, setError).then((res) => {
                setRefresh(refresh + 1);
                setPlayAnimation(true);
                setSaving(false);

                if(res?.message === 'success'){
                    document.getElementById('ComisioningModalOpen').click();
                }
            });
        }
    }
    return submitSuccess ? <Navigate to='/orders' /> :
        <>

            <div className="row">
                <div className="col-lg-12 mt-lg-0" style={{ position: 'relative' }}>
                    {/* {playAnimation ? <div className="loader-pdf"><p>Loading...</p></div> : ''} */}



                    <div className={props?.flashLoader == false ? 'card mb-4' : playAnimation ? 'card mb-4 loading' : "card mb-4"}>
                        <div className="card-body">
                            {props?.pdfDisplay == false ? (
                                <></>
                            ) : (
                                <PDF playAnimation={playAnimation} data={data} refresh={refresh + 1} signFrom={props?.signFrom}></PDF>

                            )}
                            {JSON.parse(Cookies.get('permissions')).indexOf("dabhU") !== -1 ? (
                                <div className="row">
                                    <div className="col-sm-6">
                                        {!showPad ? <div>
                                            <div className="sign-pad">
                                                <img style={{ width: '100%' }} src={data && data.order ? process.env.REACT_APP_STORAGE_URL + 'signs/' + data.order.sign : ''} alt="" />
                                                <p style={{ borderTop: '1px solid #000', textAlign: 'center' }}>Unterschrift Kunde</p>
                                            </div> <button type="button" className="btn btn-outline-dark" onClick={() => { hideSign(); }}>
                                                <i className="fa fa-eraser"></i>
                                            </button>
                                        </div> : ''}
                                        {showPad ? <div><div className="sign-pad">
                                            <SignaturePad options={{ minWidth: 1, maxWidth: 1, penColor: 'rgb(0, 0, 0)', backgroundColor: '#b9d1ff5e' }} ref={signaturePadRef} />
                                            <p style={{ borderTop: '1px solid #000', textAlign: 'center', marginTop: '18px' }}>Unterschrift Kunde</p>
                                        </div>
                                            <button type="button" className="btn btn-outline-dark" onClick={() => { signaturePadRef.current.clear(); }}>
                                                <i className="fa fa-eraser"></i>
                                            </button>   </div> : ''}

                                    </div>
                                    <div className="col-sm-6">
                                        {!showPadClient ? <div><div className="sign-pad">
                                            <i className="fa fa-pencil" onClick={() => hideSignClient()} style={{ position: 'relative', top: '140px' }}></i>
                                            <img style={{ width: '100%' }} src={data && data.order ? process.env.REACT_APP_STORAGE_URL + 'signs/' + data.order.client_sign : ''} alt="" />
                                            <p style={{ borderTop: '1px solid #000', textAlign: 'center' }}>Unterschift Installateur</p>
                                        </div> <button type="button" className="btn btn-outline-dark" onClick={() => { signaturePadRefClient.current.clear(); }}>
                                                <i className="fa fa-eraser"></i>
                                            </button></div> : ' '}
                                        {showPadClient ? <div><div className="sign-pad-new">
                                            <SignaturePad options={{ minWidth: 1, maxWidth: 1, penColor: 'rgb(0, 0, 0)', backgroundColor: '#b9d1ff5e' }} ref={signaturePadRefClient} />
                                            <p style={{ borderTop: '1px solid #000', textAlign: 'center', marginTop: '18px' }}>Unterschift Installateur</p>
                                        </div>
                                            <button type="button" className="btn btn-outline-dark" onClick={() => { signaturePadRefClient.current.clear(); }}>
                                                <i className="fa fa-eraser"></i>
                                            </button>  </div> : ''}
                                    </div>
                                </div>
                            ) : <></>}
                        </div>
                    </div>
                    {JSON.parse(Cookies.get('permissions')).indexOf("dabhU") !== -1 ? (
                        <div className={"row " + (props?.saveBtnDisplay == false ? 'd-none' : '')}>
                            <div className="col-sm-4">
                                {!saving && (
                                    <button
                                        type="button"
                                        id="saveSign"
                                        className="btn btn-primary"

                                        onClick={() => {
                                            onSubmit({});
                                        }}

                                    >
                                        {t('Abnahmeprotokol erstellen')}
                                    </button>
                                )}
                                {saving && (
                                    <button type="button" className="btn btn-disabled" disabled>
                                        {t('Abnahmeprotokol erstellen ...')}
                                    </button>
                                )}
                            </div>
                        </div>
                    ) : <></>}
                </div>
            </div >
            <ToastContainer />
        </>
        ;
}

export default DCabnahmeSign