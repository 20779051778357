import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import callFetch from "../../../../../../helpers/callFetch";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const EstimatedProductionThreeLineChart = (props) => {
  let params = useParams();
  
  const [rows, setRows] = useState({
    labels: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
    datasets: [],
  });

  useEffect(() => { 
    callFetch("estimated-production-consumption/" + (props?.projectid ? props?.projectid : params.id), "GET", []).then((res) => { 
      const resData = res?.response_data_three;

      // Log response data structure to verify it
      console.log('Response Data:', resData);

      if (resData && resData.autonomie && resData.production_kwh && resData.verbrauch) {
        setRows({
          labels: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
          datasets: [
            { 
              label: 'Produktion kWh',
              data: resData.production_kwh,
              backgroundColor: 'rgba(72, 187, 120, 0.5)',
              color: "info",
              borderColor: 'rgba(72, 187, 120, 1)',
              borderRadius: 4,
              borderSkipped: false,
              maxBarThickness: 8,
              fill: false,
            },
            {
              label: 'Verbrauch kWh',
              data: resData.verbrauch,
              backgroundColor: 'rgba(32, 141, 252, 0.5)',
              color: "dark",
              borderColor: 'rgba(32, 141, 252, 1)',
              borderRadius: 4,
              borderSkipped: false,
              maxBarThickness: 8,
              fill: false,
            },
            {
              label: 'Autonotmle In %',
              data: resData.autonomie,
              backgroundColor: 'rgba(229, 62, 62, 0.5)',
              color: "warning",
              borderColor: 'rgba(229, 62, 62, 1)',
              borderRadius: 4,
              borderSkipped: false,
              maxBarThickness: 8,
              fill: false,
            },
          ],
        });
      } else {
        console.warn("Incomplete data structure in response:", resData);
      }
    }).catch(error => {
      console.error('Error fetching data:', error);
    });
  }, [props?.projectid, params.id]);

  return rows?.datasets?.length ? (
    <DefaultLineChart chart={rows}/>
  ) : (
    <p>Loading data...</p> 
  );
};

export default EstimatedProductionThreeLineChart;
