import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import callFetch from "../../../../../../helpers/callFetch";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const EstimatedProduction = ({refreshKey}) => {
  let params = useParams();
  
  const [rows, setRows] = useState({
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [],
  });

  useEffect(() => { 
    callFetch("estimated-production-consumption/" + params.id, "GET", []).then((res) => { 
      const resData = res?.response; 
      console.log(res?.response);

      // Assuming resData contains fields for verbrauch, produktion, and eigenverbrauch as arrays
      setRows({
        labels: ['Januar', 'Februar', 'März', 'Apil', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
        datasets: [
          {
            label: 'Produktion kWh',
            data: resData.produktion, // Assuming resData contains an array for produktion
            backgroundColor: 'rgba(72, 187, 120)',
            borderRadius: 4,
            borderSkipped: false,
            maxBarThickness: 8,
          },
          {
            label: 'Verbrauch kWh',
            data: resData.verbrauch, // Assuming resData contains an array for verbrauch
            backgroundColor: 'rgba(32, 141, 252)',
            borderRadius: 4,
            borderSkipped: false,
            maxBarThickness: 8,
          },
          
          {
            label: 'Eigenverbrauch kWh',
            data: resData.eigenverbrauch, // Assuming resData contains an array for eigenverbrauch
            backgroundColor: 'rgba(229, 62, 62)',
            borderRadius: 4,
            borderSkipped: false,
            maxBarThickness: 8,
          },
        ],
      });
    }).catch(error => {
      console.error('Error fetching data:', error);
    });
  }, [params.id, refreshKey]); // Added dependency on props?.projectid and params.id

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Production and Consumption Data',
      },
    },
    scales: {
      x: {
        categoryPercentage: 0.6,
        barPercentage: 0.6,
        grid: {
          drawBorder: false,
          display: true,
          drawOnChartArea: true,
          drawTicks: false,
          borderDash: [5, 5],
        },
        ticks: {
          display: true,
          padding: 10,
          color: "#9ca2b7",
          font: {
            size: 12,
            family: "Open Sans",
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          drawBorder: false,
          display: true,
          drawOnChartArea: true,
          drawTicks: false,
          borderDash: [5, 5],
        },
        ticks: {
          display: true,
          padding: 10,
          color: "#9ca2b7",
          font: {
            size: 12,
            family: "Open Sans",
          },
        },
      },
    },
  };

  return <Bar data={rows} options={options} />;
};

export default EstimatedProduction;
