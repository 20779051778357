import React, { useMemo, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import callFetch from 'helpers/callFetch';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftEditor from "components/SoftEditor";


import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css';
import SignaturePad from 'react-signature-pad-wrapper';
import Timeline from "../../create/Timeline";
import SoftSnackbar from "components/SoftSnackbar";
import ComisioningModal from "../ComisioningModal";
import DropZoneTabs from 'components/DropZoneTabs/Index'
import Overview from '../../Overview';
import DCabnahmeSign from "../DCabnahmeSign";

const DCAbnahmeprotokoll = (props) => {
    const { t } = useTranslation();
    const [message, setMessage] = useState('Success');
    const [color, setColor] = useState('success');
    const params = useParams()
    const user = JSON.parse(Cookies.get('user'));
    const signaturePadRef = useRef(null);
    const submitBtn = useRef();
    const [saving, setSaving] = useState(false);
    const [modules, setModules] = useState([]);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [editDelay, setEditDelay] = useState(0)
    const [refresh2, setRefresh2] = useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [description, setDescription] = useState("");
    const [attachments, setAttachment] = useState([]);
    const [data, setData] = useState([]);
    const [link, setLink] = useState(null)
    const [id, setID] = useState(0)
    const [newDropzone, setNewDropzone] = useState(true);
    const [editForm, setEditForm] = useState(false);
    const [tabFiles, setTabFiles] = useState({});
    const [strgItemRefresh, setStrgItemRefresh] = useState(0);
    const [strgItem, setStrgItem] = useState({ strg_1: '' });
    const [readOnly, setReadOnly] = useState(false);
    const [displayTab, setDisplayTab] = useState('form');

    const [verbaut, setVerbaut] = useState();
    const handleVerbaut = (e) => {
        setVerbaut(e.target.checked);
    }

    const [dropZoneTabs, setDropZoneTabs] = useState([
        {
            name: 'ziegelbearbeitungMontageDachhaken',
            titel: 'Kabelverlegung unter Ziegel',
            require: false
        }, {
            name: 'montierteDachhaken',
            titel: 'Montierte Dachhaken',
            require: false
        }, {
            name: 'uKAllerGeneratorflachen',
            titel: 'UK aller Flächen',
            require: false
        }, {
            name: 'erdungGesUnterkonstruktion',
            titel: 'Erdung ges. Unterkonstruktion',
            require: false
        }, {
            name: 'dCKabelverlegung',
            titel: 'DC Kabelverlegung',
            require: false
        }, {
            name: 'abdichtungKabeleintritt',
            titel: 'Abdichtung Kabeleintritt',
            require: false
        }, {
            name: 'zwischenraumDerModuleUndZiegel',
            titel: 'Zwischenraum der Module und Ziegel',
            require: false
        }, {
            name: 'generatorenflachenAllerDachflachen',
            titel: 'Fertige Montage Fotos aller Dachflächen',
            require: false
        }, {
            name: 'typenschildDerVerbautenModule',
            titel: 'Typenschild der verbauten Module',
            require: false
        }, {
            name: 'stringplanEntGeneratorenflache',
            titel: 'Stringplan verbauter Flächen',
            require: false
        }, {
            name: 'dCUberstannungsschutz',
            titel: 'DC Überstannungsschutz',
            require: false
        }
    ]);

    const [dropZonValidationError, setDropZonValidationError] = useState('');
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

    const handleCheckboxChange = () => {
        setIsCheckboxChecked(!isCheckboxChecked);
        //console.log(isCheckboxChecked);
    };

    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <SoftSnackbar
            color={color}
            icon="check"
            title={t(message)}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={successSB}
            onClose={closeSuccessSB}
            close={closeSuccessSB}
            bgSuccess
        />
    );

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        reset,
        watch,
        formState: { errors },
    } = useForm();

    useEffect(() => {

        if (JSON.parse(Cookies.get('permissions')).indexOf("dabhU") == -1) {
            setReadOnly(true);
        }

        callFetch("module/all", "GET", []).then((res) => {
            setModules(res.data);
        });
    }, [0])

    useEffect(() => {

        if (props.project_id !== undefined) {
            callFetch("projectdistribution/" + props.project_id + "/edit?project_id=" + props.project_id, "GET", []).then((res) => {
                // setValue('modultyp', res?.data?.quations?.modul);
                // setValue('modulanzahl', res?.data?.quations?.anzahl_module);
                setEditDelay(1)
            });

            callFetch("dc_abnahmeprotokoll/" + props.project_id + "/edit", "GET", []).then((res) => {
                if (res?.data?.dcabname) {
                    setTabFiles(JSON.parse(res?.data?.dcabname?.filesdata));

                    setID(res?.data?.dcabname?.id);
                    var payload = res?.data?.dcabname?.payload ? (typeof res?.data?.dcabname?.payload === 'string' ? JSON.parse(res?.data?.dcabname?.payload) : res?.data?.dcabname?.payload) : {};

                    setStrgItem(JSON.parse(res?.data?.dcabname?.strg))

                    setDescription(payload?.description && payload?.description != 'null' ? payload?.description : '')

                    if (JSON.parse(Cookies.get('permissions')).indexOf("dabhU") !== -1) {
                        signaturePadRef.current.fromDataURL(res?.data?.dcabname?.drawing);
                    }

                    // setValue('modultyp', res?.data?.dcabname?.modultyp)
                    // setValue('modulanzahl', res?.data?.dcabname.modulanzahl)
                    setLink(res?.data?.pdf)
                    for (let [key, value] of Object.entries(res?.data?.dcabname)) {
                        if (key == 'payload') {
                            for (let [key, value] of Object.entries(payload)) {
                                setValue(key, (value == null || value == 'null' ? "" : (value === 'false' || value === '0' ? 0 : value === 'true' || value === '1' ? 1 : value)));
                            }
                        } else if (key == 'filesdata') {
                            setTabFiles(JSON.parse(value));
                        } else if (key == 'strg') {
                            var strg = JSON.parse(value);
                            for (let [strg_key, strg_value] of Object.entries(strg)) {
                                setValue('strg[' + strg_key + ']', strg_value);
                            }
                        } else if (key == 'volt') {
                            var volt = JSON.parse(value);
                            for (let [volt_key, volt_value] of Object.entries(volt)) {
                                setValue('volt[' + volt_key + ']', volt_value);
                            }
                        } else {
                            setValue(key, (value == null || value == 'null' ? "" : value));
                        }
                    }
                    setStrgItemRefresh(strgItemRefresh + 1);
                }
            })
        }

    }, [refresh]);

    const setCallBackData = (data) => {
        setTabFiles(data);

        setDropZonValidationError('');
        for (let [key, dropZoneTab] of Object.entries(dropZoneTabs)) {
            if (dropZoneTab?.require === true && (!data[dropZoneTab?.name] || data[dropZoneTab?.name]?.length <= 0)) {
                setDropZonValidationError(dropZoneTab?.titel + ' is required')
                return;
            }
        }
    }

    const onSubmit = (formData) => {
        if (readOnly) return;

        setDropZonValidationError('');
        for (let [key, dropZoneTab] of Object.entries(dropZoneTabs)) {
            if (dropZoneTab?.require === true && (!tabFiles[dropZoneTab?.name] || tabFiles[dropZoneTab?.name]?.length <= 0)) {
                setDropZonValidationError(dropZoneTab?.titel + ' is required')
                return;
            }
        }

        setSaving(true);

        var loadingElement = document.querySelector('.res-options .btn-primary-save');
        loadingElement.classList.add("btn-secondary");
        loadingElement.innerHTML = t('wird geladen...');

        formData.description = description;
        formData.sign = signaturePadRef.current.toDataURL('image/png');
        formData.filesdata = JSON.stringify(tabFiles);
        formData.strg = JSON.stringify(getValues('strg'));
        formData.volt = JSON.stringify(getValues('volt'));

        if (id == 0) {
            formData.project_id = props.project_id;
            formData.eidt_id = Cookies.get('dc_pdf');
        }

        //   Cookies.remove('dc_pdf')
        if (signaturePadRef.current.isEmpty()) {
            /*
            setColor('error')
            setMessage('Fill Out Skizze/Modulbelegung');
            setTimeout(() => {
                setSaving(false);

                loadingElement.classList.remove("btn-secondary");
                loadingElement.innerHTML = t('Save');

                openSuccessSB();
            }, 2000);
            return;
            */
        }

        callFetch((id == 0 ? "dc_abnahmeprotokoll" : "dc_abnahmeprotokoll/" + id), "POST", formData, setError).then((res) => {
            setData(res);
            setLink(res?.data?.pdf)
            setID(res?.data?.id)

            setColor('success')
            setMessage('Success');

            setTimeout(() => {
                setSaving(false);
                openSuccessSB();

                loadingElement.classList.remove("btn-secondary");
                loadingElement.innerHTML = t('Save');

                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                document.getElementById("main-content").scrollTop = 0;

                /*
                if (id == 0) {
                    document.getElementById('ComisioningModalOpen').click();
                }
                */

                if (!res.ok) return;
                setSubmitSuccess(true);
                setRefresh(refresh + 1)
                clearForm();

            }, 2000);
        });
    };
    const clearForm = () => {
        reset({
            id: 0,
            title: '',
            status: '',
            description: '',
            attachments: JSON.stringify([]),
        });

        setNewDropzone(true);
        setEditForm(false);
        setAttachment([]);
        setDescription("");
    }



    return (

        <>

            <div className="row">
                <div className="col-lg-9 inner-main">
                    <Overview className="mb-3" project_id={params?.id} title={t('DC Abnahmeprotokoll')} ></Overview>
                    {displayTab === 'form' ? (
                        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                            <SoftBox>
                                {
                                    link !== null && <a className="opacity-0" target={"_blank"} id="dc_abnahmeprotokoll" href={link && link} ></a>
                                }

                                <input type="hidden" id="dcid" value={id} />

                                {id > 0 ? <input type="hidden" defaultValue="PUT" {...register("_method")} /> : ''}


                                <Card sx={{ overflow: "visible" }}>
                                    <SoftBox p={3}>
                                        <SoftTypography variant="h5">{t('DC Abnahmeprotokoll')}</SoftTypography>
                                    </SoftBox>
                                    <SoftBox p={2} lineHeight={1}>
                                        <Grid mb={2} container spacing={2}>
                                            {
                                                Object.keys(strgItem).map(function (keyName, index) {
                                                    return (
                                                        <>
                                                            <Grid item xs={6} sm={6} md={2} lg={2}>
                                                                <label>Strg {(index + 1)}</label>
                                                                <input
                                                                    type="text" className="form-control"
                                                                    placeholder={"Strg " + (index + 1)}
                                                                    {...register('strg[' + keyName + ']', {
                                                                        required: false
                                                                    })}
                                                                    readOnly={readOnly}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={6} sm={6} md={2} lg={2}>
                                                                <label>Volt {(index + 1)}</label>
                                                                <input
                                                                    type="text" className="form-control"
                                                                    placeholder={"Volt " + (index + 1)}
                                                                    {...register('volt[' + keyName + ']', {
                                                                        required: false
                                                                    })}
                                                                    readOnly={readOnly}
                                                                />
                                                            </Grid>
                                                        </>
                                                    )
                                                })
                                            }
                                            {JSON.parse(Cookies.get('permissions')).indexOf("dabhU") !== -1 ? (
                                                <Grid item xs={6} sm={6} md={2} lg={2}>
                                                    <a
                                                        className="btn btn-secondary mb-0"
                                                        style={{ marginTop: '22px' }}
                                                        onClick={() => {
                                                            var strgItems = strgItem;
                                                            strgItems['strg_' + ((Object.keys(strgItem).length + 1))] = '';
                                                            setStrgItem(strgItems);
                                                            setStrgItemRefresh(strgItemRefresh + 1)
                                                        }}
                                                    >{'+ ' + t('Add')}</a>
                                                </Grid>
                                            ) : ''}
                                        </Grid>


                                        <Grid my={2} container spacing={2} >

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={8} sm={12} md={9} lg={9} >
                                                        <SoftTypography variant="p" display="block" mt={1} fontWeight="bold" fontSize="14px">Fotodokumentation</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={4} sm={12} md={3} lg={3} >
                                                        <SoftTypography variant="p" display="block" mt={1} fontWeight="bold" fontSize="14px">Durchgeführt</SoftTypography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={10} sm={12} md={9} lg={9} >
                                                        <SoftTypography variant="p" display="block" mt={1} fontWeight="normal" fontSize="14px">Bilder Arbeitsschutz (Gerüst)</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={2} sm={12} md={3} lg={3} >
                                                        <div className="d-flex align-items-center">
                                                            <div className="form-check d-inline-block text-center">
                                                                <input type="checkbox" {...register("pictures_safety_scaffolding")} className="form-check-input msp-2" value={1} />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={10} sm={12} md={9} lg={9} >
                                                        <SoftTypography variant="p" display="block" mt={1} fontWeight="normal" fontSize="14px">Bilder Unterkonstruktion (Ohne Module) </SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={2} sm={12} md={3} lg={3} >
                                                        <div className="d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input type="checkbox" {...register("pictures_substructure_no_modules")} className="form-check-input msp-2" value={1} />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={10} sm={12} md={9} lg={9} >
                                                        <SoftTypography variant="p" display="block" mt={1} fontWeight="normal" fontSize="14px">Bilder Kabelweg  </SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={2} sm={12} md={3} lg={3} >
                                                        <div className="d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input type="checkbox" {...register("pictures_cable_route")} className="form-check-input msp-2" value={1} />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={10} sm={12} md={9} lg={9} >
                                                        <SoftTypography variant="p" display="block" mt={1} fontWeight="normal" fontSize="14px">Bilder Modulbelegung </SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={2} sm={12} md={3} lg={3} >
                                                        <div className="d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input type="checkbox" {...register("pictures_module_placement")} className="form-check-input msp-2" value={1} />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={10} sm={12} md={9} lg={9} >
                                                        <SoftTypography variant="p" display="block" mt={1} fontWeight="normal" fontSize="14px">Bilder Speicher Standort</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={2} sm={12} md={3} lg={3} >
                                                        <div className="d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input type="checkbox" {...register("image_storage_location")} className="form-check-input msp-2" value={1} />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>


                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={10} sm={12} md={9} lg={9} >
                                                        <SoftTypography variant="p" display="block" mt={1} fontWeight="normal" fontSize="14px">DC-Kabel 2m Überhang ab Fußboden am Speicherstandort</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={2} sm={12} md={3} lg={3} >
                                                        <div className="d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input type="checkbox" {...register("dc_cable_overhang_2m_storage")} className="form-check-input msp-2" value={1} />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={10} sm={12} md={9} lg={9} >
                                                        <SoftTypography variant="p" display="block" mt={1} fontWeight="normal" fontSize="14px">Beschriftung der Stringleitung (Wie Projektbericht)</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={2} sm={12} md={3} lg={3} >
                                                        <div className="d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input type="checkbox" {...register("string_line_labeling")} className="form-check-input msp-2" value={1} />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={10} sm={12} md={9} lg={9} >
                                                        <SoftTypography variant="p" display="block" mt={1} fontWeight="normal" fontSize="14px">Spannung gemessen </SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={2} sm={12} md={3} lg={3} >
                                                        <div className="d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input type="checkbox" {...register("voltage_measured")} className="form-check-input msp-2" value={1} />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={10} sm={12} md={9} lg={9} >
                                                        <SoftTypography variant="p" display="block" mt={1} fontWeight="normal" fontSize="14px">DC-Kabel am Speicherstandort isoliert</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={2} sm={12} md={3} lg={3} >
                                                        <div className="d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input type="checkbox" {...register("dc_cable_insulated_storage")} className="form-check-input msp-2" value={1} />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={10} sm={12} md={9} lg={9} >
                                                        <SoftTypography variant="p" display="block" mt={1} fontWeight="normal" fontSize="14px">Fotos aller Typenschilder von Speicher und Wechselrichtern (mit Seriennummern) </SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={2} sm={12} md={3} lg={3} >
                                                        <div className="d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input type="checkbox" {...register("photos_type_plates_storage_inverters")} className="form-check-input msp-2" value={1} />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={10} sm={12} md={9} lg={9} >
                                                        <SoftTypography variant="p" display="block" mt={1} fontWeight="normal" fontSize="14px">Fotos aller Typenschilder der verbauten Batterien (mit Seriennummern) </SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={2} sm={12} md={3} lg={3} >
                                                        <div className="d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input type="checkbox" {...register("photo_battery_serial_numbers")} className="form-check-input msp-2" value={1} />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <br />
                                        <br />

                                        <Grid my={2} container spacing={1} >
                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={1} >
                                                    <Grid item xs={12} sm={4} md={4} lg={4} >
                                                        <SoftTypography variant="p" display="block" mt={3} fontWeight="normal" fontSize="14px">Bezeichnung laut Strangplan <br />
                                                            / Dachfächenausrichtung</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={2} sm={1} md={1} lg={1} >
                                                        <label>MPP1</label>
                                                        <input type="text" {...register('designation_strangplan_roof_orientation_mpp1')} className="form-control" />
                                                    </Grid>
                                                    <Grid item xs={2} sm={1} md={1} lg={1} >
                                                        <label>MPP2</label>
                                                        <input type="text" {...register('designation_strangplan_roof_orientation_mpp2')} className="form-control" />
                                                    </Grid>
                                                    <Grid item xs={2} sm={1} md={1} lg={1} >
                                                        <label>MPP3</label>
                                                        <input type="text" {...register('designation_strangplan_roof_orientation_mpp3')} className="form-control" />
                                                    </Grid>
                                                    <Grid item xs={2} sm={1} md={1} lg={1} >
                                                        <label>MPP4</label>
                                                        <input type="text" {...register('designation_strangplan_roof_orientation_mpp4')} className="form-control" />
                                                    </Grid>
                                                    <Grid item xs={2} sm={1} md={1} lg={1} >
                                                        <label>MPP5</label>
                                                        <input type="text" {...register('designation_strangplan_roof_orientation_mpp5')} className="form-control" />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={1} >
                                                    <Grid item xs={12} sm={4} md={4} lg={4} >
                                                        <SoftTypography variant="p" display="block" mt={3} fontWeight="normal" fontSize="14px"> Leerlaufspannung U o/c <br />
                                                            (Prüfspannung 100V)</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={2} sm={1} md={1} lg={1} >
                                                        <label>MPP1</label>
                                                        <input type="text" {...register('open_circuit_voltage_mpp1')} className="form-control" />
                                                    </Grid>
                                                    <Grid item xs={2} sm={1} md={1} lg={1} >
                                                        <label>MPP2</label>
                                                        <input type="text" {...register('open_circuit_voltage_mpp2')} className="form-control" />
                                                    </Grid>
                                                    <Grid item xs={2} sm={1} md={1} lg={1} >
                                                        <label>MPP3</label>
                                                        <input type="text" {...register('open_circuit_voltage_mpp3')} className="form-control" />
                                                    </Grid>
                                                    <Grid item xs={2} sm={1} md={1} lg={1} >
                                                        <label>MPP4</label>
                                                        <input type="text" {...register('open_circuit_voltage_mpp4')} className="form-control" />
                                                    </Grid>
                                                    <Grid item xs={2} sm={1} md={1} lg={1} >
                                                        <label>MPP5</label>
                                                        <input type="text" {...register('open_circuit_voltage_mpp5')} className="form-control" />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={1} >
                                                    <Grid item xs={12} sm={4} md={4} lg={4} >
                                                        <SoftTypography variant="p" display="block" mt={3} fontWeight="normal" fontSize="14px"> Isolationswiderstand M�</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={2} sm={1} md={1} lg={1} >
                                                        <label>MPP1</label>
                                                        <input type="text" {...register('isolation_resistance_mpp1')} className="form-control" />
                                                    </Grid>
                                                    <Grid item xs={2} sm={1} md={1} lg={1} >
                                                        <label>MPP2</label>
                                                        <input type="text" {...register('isolation_resistance_mpp2')} className="form-control" />
                                                    </Grid>
                                                    <Grid item xs={2} sm={1} md={1} lg={1} >
                                                        <label>MPP3</label>
                                                        <input type="text" {...register('isolation_resistance_mpp3')} className="form-control" />
                                                    </Grid>
                                                    <Grid item xs={2} sm={1} md={1} lg={1} >
                                                        <label>MPP4</label>
                                                        <input type="text" {...register('isolation_resistance_mpp4')} className="form-control" />
                                                    </Grid>
                                                    <Grid item xs={2} sm={1} md={1} lg={1} >
                                                        <label>MPP5</label>
                                                        <input type="text" {...register('isolation_resistance_mpp5')} className="form-control" />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={1} >
                                                    <Grid item xs={12} sm={4} md={4} lg={4} >
                                                        <SoftTypography variant="p" display="block" mt={3} fontWeight="normal" fontSize="14px"> Installierter Leitungsquerschnitt</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={2} sm={1} md={1} lg={1} >
                                                        <label>MPP1</label>
                                                        <input type="text" {...register('installed_cable_cross_section_mpp1')} className="form-control" />
                                                    </Grid>
                                                    <Grid item xs={2} sm={1} md={1} lg={1} >
                                                        <label>MPP2</label>
                                                        <input type="text" {...register('installed_cable_cross_section_mpp2')} className="form-control" />
                                                    </Grid>
                                                    <Grid item xs={2} sm={1} md={1} lg={1} >
                                                        <label>MPP3</label>
                                                        <input type="text" {...register('installed_cable_cross_section_mpp3')} className="form-control" />
                                                    </Grid>
                                                    <Grid item xs={2} sm={1} md={1} lg={1} >
                                                        <label>MPP4</label>
                                                        <input type="text" {...register('installed_cable_cross_section_mpp4')} className="form-control" />
                                                    </Grid>
                                                    <Grid item xs={2} sm={1} md={1} lg={1} >
                                                        <label>MPP5</label>
                                                        <input type="text" {...register('installed_cable_cross_section_mpp5')} className="form-control" />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <br />
                                        <br />

                                        <Grid my={2} container spacing={2} >

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={8} sm={12} md={6} lg={6} >
                                                        <SoftTypography variant="p" fontWeight="bold" fontSize="14px">Montageabnahme, Sichtprüfung und Abgleich mit der Planung</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={2} sm={12} md={3} lg={3} >
                                                        <SoftTypography variant="p" fontWeight="bold" fontSize="14px">In Ordnung</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={2} sm={12} md={3} lg={3} >
                                                        <SoftTypography variant="p" fontWeight="bold" fontSize="14px">Beanstandet</SoftTypography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>



                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={8} sm={12} md={6} lg={6} >
                                                        <SoftTypography variant="p" fontWeight="" fontSize="14px">Anlagenmontage ohne sichtbare Schäden an Anlage, Dach, Gebäude usw</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={2} sm={12} md={3} lg={3} >
                                                        <div className="d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    // checked={selectedOption == 'Yes'}
                                                                    // onClick={() => handleOptionChange('Yes')}
                                                                    type="checkbox"
                                                                    {...register("system_installation_no_damage")}
                                                                    className="form-check-input  msp-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                            <label className="mt-2 uppercase" ></label>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                    <Grid item xs={2} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    // checked={selectedOption == 'No'}
                                                                    // onClick={() => handleOptionChange('No')}
                                                                    type="checkbox"
                                                                    {...register("system_installation_no_damage_no")}
                                                                    className="form-check-input  msp-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                            <label className="mt-2 uppercase" ></label>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={8} sm={12} md={6} lg={6} >
                                                        <SoftTypography variant="p" fontWeight="" fontSize="14px">Montagesystem, Dachdurchdringungen/Abdichtungen (Dachfläche)</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={2} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    // checked={selectedOption1 == 'Yes'}
                                                                    //     onClick={() => handleOptionChange1('Yes')}
                                                                    type="checkbox"
                                                                    {...register("mounting_roof_penetrations_sealing")}

                                                                    className="form-check-input  msp-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                            <label className="mt-2 uppercase" ></label>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                    <Grid item xs={2} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    // checked={selectedOption1 == 'No'}
                                                                    // onClick={() => handleOptionChange1('No')}
                                                                    type="checkbox"
                                                                    {...register("mounting_roof_penetrations_sealing_no")}

                                                                    className="form-check-input  msp-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                            <label className="mt-2 uppercase" ></label>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={8} sm={12} md={6} lg={6} >
                                                        <SoftTypography variant="p" fontWeight="" fontSize="14px">Die Modulbelegung erfolgte gemäß Dachbelegungsplan</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={2} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    // checked={selectedOption2 == 'Yes'}
                                                                    //     onClick={() => handleOptionChange2('Yes')}
                                                                    type="checkbox"
                                                                    {...register("module_allocation_roof_plan")}

                                                                    className="form-check-input  msp-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                            <label className="mt-2 uppercase" ></label>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                    <Grid item xs={2} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    // checked={selectedOption2 == 'No'}
                                                                    // onClick={() => handleOptionChange2('No')}
                                                                    type="checkbox"
                                                                    {...register("module_allocation_roof_plan_no")}

                                                                    className="form-check-input  msp-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                            <label className="mt-2 uppercase" ></label>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={8} sm={12} md={6} lg={6} >
                                                        <SoftTypography variant="p" fontWeight="" fontSize="14px">Änderungen an der Dachbelegung sind gemäß Skizze (oben) abgestimmt(streichen, wenn nicht relevant)</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={2} sm={12} md={3} lg={3} >
                                                        <div className="d-flex">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    // checked={selectedOption3 == 'Yes'}
                                                                    //     onClick={() => handleOptionChange3('Yes')}
                                                                    type="checkbox"
                                                                    {...register("roof_layout_changes_coordinated")}

                                                                    className="form-check-input  msp-1"
                                                                    value={1}
                                                                    onChange={(e) => handleVerbaut(e)}
                                                                />
                                                            </div>
                                                            <label className="mt-2 uppercase" ></label>

                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                    <Grid item xs={2} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    // checked={selectedOption3 == 'No'}
                                                                    // onClick={() => handleOptionChange3('No')}
                                                                    type="checkbox"
                                                                    {...register("roof_layout_changes_coordinated_no")}

                                                                    className="form-check-input  msp-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                            <label className="mt-2 uppercase" ></label>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={8} sm={12} md={6} lg={6} >
                                                        <SoftTypography variant="p" fontWeight="" fontSize="14px">Verkabelung hergestellt bis zum Speicher ggf. Wechselrichter</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={2} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    // checked={selectedOption4 == 'Yes'}
                                                                    // onClick={() => handleOptionChange4('Yes')}
                                                                    type="checkbox"
                                                                    {...register("wiring_storage_inverter_established")}

                                                                    className="form-check-input  msp-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                            <label className="mt-2 uppercase" ></label>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                    <Grid item xs={2} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    //      checked={selectedOption4 == 'No'}
                                                                    // onClick={() => handleOptionChange4('No')}
                                                                    type="checkbox"
                                                                    {...register("wiring_storage_inverter_established_no")}

                                                                    className="form-check-input  msp-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                            <label className="mt-2 uppercase" ></label>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={8} sm={12} md={6} lg={6} >
                                                        <SoftTypography variant="p" fontWeight="" fontSize="14px">Hauseintritte der Leitungen abgedichtet (Auch Wandeintritte)</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={2} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    // checked={selectedOption5 == 'Yes'}
                                                                    // onClick={() => handleOptionChange5('Yes')}
                                                                    type="checkbox"
                                                                    {...register("house_pipe_inlets_sealed")}

                                                                    className="form-check-input  msp-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                            <label className="mt-2 uppercase" ></label>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                    <Grid item xs={2} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    //  checked={selectedOption5 == 'No'}
                                                                    // onClick={() => handleOptionChange5('No')}
                                                                    type="checkbox"
                                                                    {...register("house_pipe_inlets_sealed_no")}

                                                                    className="form-check-input  msp-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                            <label className="mt-2 uppercase" ></label>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={8} sm={12} md={6} lg={6} >
                                                        <SoftTypography variant="p" fontWeight="" fontSize="14px" >Kabelenden isoliert! (Dokumentation durch Foto). ACHTUNG LEBENSGEFAHR</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={2} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    //  checked={selectedOption6 == 'Yes'}
                                                                    // onClick={() => handleOptionChange6('Yes')}
                                                                    type="checkbox"
                                                                    {...register("cable_ends_insulated")}

                                                                    className="form-check-input  msp-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                    <Grid item xs={2} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    //       checked={selectedOption6 == 'No'}
                                                                    // onClick={() => handleOptionChange6('No')}
                                                                    type="checkbox"
                                                                    {...register("cable_ends_insulated_no")}

                                                                    className="form-check-input  msp-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                            <label className="mt-2 uppercase" ></label>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={8} sm={12} md={6} lg={6} >
                                                        <SoftTypography variant="p" fontWeight="" fontSize="14px" >Die Lieferung aller Materialien ist vollständig erfolgt</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={2} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    // checked={selectedOption7 == 'Yes'}
                                                                    // onClick={() => handleOptionChange7('Yes')}
                                                                    type="checkbox"
                                                                    {...register("materials_delivery_completed")}

                                                                    className="form-check-input  msp-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                    <Grid item xs={2} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    //      checked={selectedOption7 == 'No'}
                                                                    // onClick={() => handleOptionChange7('No')}
                                                                    type="checkbox"
                                                                    {...register("materials_delivery_completed_no")}

                                                                    className="form-check-input  msp-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                            <label className="mt-2 uppercase" ></label>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={12} sm={12} md={12} lg={12} >
                                                        <SoftBox
                                                            display="flex"
                                                            flexDirection="column"
                                                            justifyContent="flex-end"
                                                            height="100%"
                                                        >
                                                            <SoftBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                    {t('Sonstige Bemerkungen')}
                                                                </SoftTypography>
                                                            </SoftBox>
                                                            {false ? (
                                                                <>
                                                                    {description}
                                                                </>
                                                            ) : (
                                                                <SoftEditor
                                                                    value={description}
                                                                    onChange={setDescription}
                                                                />
                                                            )}
                                                        </SoftBox>
                                                    </Grid>
                                                </Grid>
                                            </Grid>


                                        </Grid>
                                    </SoftBox>
                                </Card>
                            </SoftBox>

                            <SoftBox my={2}>
                                <Card>
                                    <SoftBox p={3}>
                                        <SoftTypography variant="h5">{t('Files')}</SoftTypography>
                                    </SoftBox>

                                    <SoftBox px={3} pb={3}>
                                        <DropZoneTabs
                                            tabs={dropZoneTabs}
                                            defaultFiles={tabFiles}
                                            defaultTab={'ziegelbearbeitungMontageDachhaken'}
                                            callBackData={setCallBackData}
                                            readOnly={readOnly}
                                            dropZonValidationError={dropZonValidationError}
                                        />
                                    </SoftBox>
                                </Card>
                            </SoftBox>


                            {JSON.parse(Cookies.get('permissions')).indexOf("dabhU") !== -1 ? (
                                <SoftBox my={2}>
                                    <Card sx={{ overflow: "visible" }}>
                                        <SoftBox p={3}>
                                            <SoftTypography variant="h5">{t(' Skizze/Modulbelegung')}</SoftTypography>
                                        </SoftBox>

                                        <SignaturePad options={{ minWidth: 1, maxWidth: 1, penColor: 'rgb(0, 0, 0)' }} ref={signaturePadRef} />

                                        <Grid container px={2}>
                                            <Grid item sx={12} md={12} display="flex" justifyContent="center">
                                                <button type="button" className="btn btn-outline-dark" onClick={() => { signaturePadRef.current.clear(); }}>
                                                    <i className="fa fa-eraser"></i>
                                                </button>
                                            </Grid>
                                        </Grid>

                                    </Card>
                                </SoftBox>
                            ) : <></>}

                            <SoftBox>
                                {JSON.parse(Cookies.get('permissions')).indexOf("dabhU") !== -1 ? (
                                    <>
                                        <a href="#" className="btn btn-danger mx-2 d-none" onClick={() => { clearForm() }}>
                                            {t('Cancel')}
                                        </a>
                                        {!saving && (
                                            <button id="dcAbname" type="submit" className="btn btn-primary">
                                                {t('Save')}
                                            </button>
                                        )}
                                        {saving && (
                                            <button type="button" className="btn btn-secondary" disabled>
                                                {t('wird geladen...')}
                                            </button>
                                        )}
                                    </>
                                ) : <></>}
                                <a target='_blank' className="btn btn-primary mx-2" href={Cookies.get('backend_url') + 'dcabname/' + (id ? id : 'empty')}>{t('PDF')}</a>
                            </SoftBox>
                        </form>
                    ) : displayTab === 'sign' ?
                        <>
                            <DCabnahmeSign saveBtnDisplay={true} flashLoader={false} pdfDisplay={true} project_id={params?.id} id={id} />
                        </>
                        : ''}
                </div>
                <div className="col-lg-3 inner-main">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-3">
                                <div className="card-body p-3">
                                    <div className="d-flex">
                                        <div>
                                            <div className="icon icon-shape bg-gradient-dark text-center border-radius-md">
                                                <i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="ms-3">
                                            <div className="numbers">
                                                <p className="text-sm mb-0 text-capitalize font-weight-bold">{t('Project Nr.')}</p>
                                                <h5 className="font-weight-bolder mb-0">
                                                    {props.project_id ? props.project_id : '---'}
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {displayTab === 'form' ? (
                                <>
                                    {params?.id && id ?
                                        <button
                                            className="btn btn-primary"
                                            style={{ width: '100%', backgroundColor: '#CB0C9F', borderColor: '#CB0C9F' }}
                                            onClick={() => { setDisplayTab('sign') }}
                                        >{t('Abnahmeprotokol erstellen')}</button>
                                        : <button
                                            className="btn btn-secondary"
                                            style={{ width: '100%' }}
                                        >{t('Abnahmeprotokol erstellen')}</button>
                                    }
                                </>
                            ) : (
                                <button
                                    className="btn btn-secondary"
                                    style={{ width: '100%' }}
                                    onClick={() => { setDisplayTab('form') }}
                                >{t('Zürück')}</button>
                            )}
                        </div>
                    </div>

                    {/*<Comisioning projectid={props.project_id} id={id} type={'dc'}></Comisioning>*/}
                    <ComisioningModal projectid={props.project_id} id={id} type={'dc'} setRefresh={setRefresh} />

                    <Timeline refresh={refresh} dcStatusEdit={true} />
                </div>
                {renderSuccessSB}
            </div>
        </>
    )
}

export default DCAbnahmeprotokoll