/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { React, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import Cookies from 'js-cookie';
import callFetch from "../../../../helpers/callFetch";
// Images
import burceMars from "assets/images/bruce-mars.jpg";
import { useForm } from "react-hook-form";
function Header() {
  const [visible, setVisible] = useState(true);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [user, setUser] = useState(JSON.parse(Cookies.get('user')));
  const [uploadedImage, setUploadedImage] = useState('');
  const handleSetVisible = () => setVisible(!visible);

  const {
    reset, resetField, register, handleSubmit, setError, setValue, getValues,
    formState: { errors },
  } = useForm();

  function uploadImage() {
    document.getElementById('imageUpload').click();
  }

  const handleChangeImage = (e) => {
    if (!e.target.files) return;
    onSubmit({
      attachment: e.target.files
    })
  }

  const onSubmit = (formData) => {
    setUploadLoading(true)
    callFetch("update-profile-picture", "POST", formData, setError).then((res) => {
      setUploadLoading(false)
      if (res?.data) {
        var userData = JSON.parse(Cookies.get('user'));
        userData.photo = res?.data;
        setUser(userData)
        Cookies.set('user', JSON.stringify(userData));
      }
    });
  };

  return (
    <Card id="profile">
      <SoftBox p={2}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            {uploadedImage && (
              <img
                src={uploadedImage}
                alt="profile-image"
                className="avatar-cs"
              />
            )}

            {!uploadedImage && (
              <>
                <img
                  src={user.photo ? Cookies.get('backend_url') + 'storage/avatar/' + user.photo : '/assets/img/placeholder.png'}
                  alt="profile-image"
                  className="avatar-cs"
                  accept={'.jpg,.png,.jpeg'}
                />
              </>
            )}

            {uploadLoading ? (
              <i
                className="fa-solid fa-spinner fa-spin"
                style={{
                  position: 'absolute',
                  marginTop: '-21px',
                  marginLeft: '50px',
                  background: '#000000',
                  width: '25px',
                  height: '25px',
                  borderRadius: '50%',
                  color: '#ffffff',
                  fontSize: '14px',
                  textAlign: 'center',
                  lineHeight: '24px',
                  cursor: 'pointer'
                }}
              ></i>
            ) : (
              <i
                className="fa fa-pencil"
                onClick={() => uploadImage()}
                style={{
                  position: 'absolute',
                  marginTop: '-21px',
                  marginLeft: '50px',
                  background: '#000000',
                  width: '25px',
                  height: '25px',
                  borderRadius: '50%',
                  color: '#ffffff',
                  fontSize: '14px',
                  textAlign: 'center',
                  lineHeight: '24px',
                  cursor: 'pointer'
                }}
              ></i>
            )}

            <form id="avatarForm">
              <input
                id="imageUpload"
                type="file"
                accept={'.jpg,.png,.jpeg'}
                onChange={(e) => {
                  handleChangeImage(e)
                }}
              />
            </form>
          </Grid>
          <Grid item>
            <SoftBox height="100%" mt={0.5} lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                {user && user.name ? user.name : ''}
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3} sx={{ ml: "auto" }}>
            <SoftBox
              display="flex"
              justifyContent={{ md: "flex-end" }}
              alignItems="center"
              lineHeight={1}
            >
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

export default Header;
