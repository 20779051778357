import React, { useRef, useState } from "react";

const EECButton = (props) => {
   
  return (
    <button style={{backgroundColor: props.bgColor, padding: props.padding, width: props.width, marginTop: '-5px'}} className="eec-button-cmp">{props.text}</button>
  );
};

export default EECButton;
