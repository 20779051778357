import { React, useMemo, useEffect, useState, useRef } from "react";

// @mui material components
import { Grid, Menu, MenuItem, Typography } from "@mui/material";

//import OperationalPlan from "./OperationalPlan";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import callFetch from "helpers/callFetch";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import dateFormat, { masks } from "dateformat";
import { NavLink } from "react-router-dom";
//import ProjectPlannedTechnicianLocation from "pages/FindNearest/ProjectPlannedTechnicianLocation";
import SoftSnackbar from "components/SoftSnackbar";
import DatePicker from "react-multi-date-picker";
import RoutePlane from "./RoutePlan/Index";
import Tooltip from "@mui/material/Tooltip";
import Cookies from 'js-cookie';
//import FindNearestUsersByProject from 'pages/FindNearest/UsersByProject'
import ReclamationShow from 'pages/reclamation/Show';

// Returns the ISO week of the date.
Date.prototype.getWeek = function () {
    var date = new Date(this.getTime());
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    var week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
}

function OrdersToPlan(props) {
    const { t } = useTranslation();
    const [refresh, setRefresh] = useState(0);
    const [data, setData] = useState([]);
    const [distanceDuration, setDistanceDuration] = useState({});
    const [employees, setEmployees] = useState([]);
    const [dc_technician, setDc_technician] = useState([]);
    const [ac_technician, setAc_technician] = useState([]);
    const [totalPlan, setTotalPlan] = useState(0);
    const [totalDuedPlan, setTotalDuedPlan] = useState(0);
    const [saving, setSaving] = useState(false);
    const [planSaving, setPlanSaving] = useState(false);
    const [refresh2, setRefresh2] = useState(0);
    const [refresh3, setRefresh3] = useState(0);
    const [refresh4, setRefresh4] = useState(0);
    const [planSubmitButton, setPlanSubmitButton] = useState(false);
    const [showPlanProjectRoute, setShowPlanProjectRoute] = useState(0);
    const [showPlanProjectRouteMitarbeiter, setShowPlanProjectRouteMitarbeiter] = useState(0);
    const [showPlanProjectRouteDate, setShowPlanProjectRouteDate] = useState('');
    const [showPlanProjectRouteTime, setShowPlanProjectRouteTime] = useState('');
    const [refreshNearestMitarbeiter, setRefreshNearestMitarbeiter] = useState(0);
    const [invalidScheduleProjectids, setInvalidScheduleProjectids] = useState([]);
    const [messageSb, setMessageSB] = useState(false);
    const [messageSbColor, setMessageSbColor] = useState('success');
    const [messageSbTitle, setMessageSbTitle] = useState('Something wrong!');
    const [messageSbIcon, setMessageSbIcon] = useState('check');
    const [planType, setPlanType] = useState((JSON.parse(Cookies.get('permissions')).indexOf("OnlyDCP") !== -1 ? 'dcPlan' : JSON.parse(Cookies.get('permissions')).indexOf("OnlyACP") !== -1 ? 'acPlan' : ''));
    const [planForm, setPlanForm] = useState('Project');
    const [sortBy, setSortBy] = useState('Date');
    const [showmap, setShowmap] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [absenceDate, setAbsenceDate] = useState([]);
    const [routeNumber, setRouteNumber] = useState('');
    const [routePlan, setRoutePlan] = useState([]);
    const [showRoutePlanScreen, setShowRoutePlanScreen] = useState(false);
    const [viewRelamation, setViewRelamation] = useState({});
    const [search, setSearch] = useState('');
    const [searching, setSearching] = useState(false);
    const [selectedProjects, setSelectedProjects] = useState([]);
    const [kiPlanung, setKiPlanung] = useState(false);

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        reset,
        formState: { errors },
    } = useForm();

    const renderMessageSB = (
        <SoftSnackbar
            color={messageSbColor}
            backgroundColor={'success'}
            icon={messageSbIcon}
            title={t(messageSbTitle)}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={messageSb}
            onClose={() => setMessageSB(false)}
            close={() => setMessageSB(false)}
        //bgWhite={messageSbColor}
        />
    );

    useEffect(() => {
        if (!endDate || !startDate) return;

        var routePlanData = [];
        for (let [key, value] of Object.entries(data)) {

            if (value?.plan_form == 'Project') {
                var dateRange = { start: value?.assembly_date, end: value?.assembly_date };

                if (!showRoutePlanScreen) {
                    if ((dateRange?.start >= startDate && dateRange?.start <= endDate) || (startDate >= dateRange?.start && startDate <= dateRange?.end)) {
                        routePlanData.push(value);
                        setValue("project_nr[" + value?.id + "]", true);
                        setRefresh2(refresh2 + 1);
                    } else if (kiPlanung) {
                        setValue("project_nr[" + value?.id + "]", false);
                        setRefresh2(refresh2 + 1);
                    }
                }
            }
        }

        setRoutePlan(routePlanData);

    }, [startDate, endDate, absenceDate, routeNumber]);

    useEffect(() => {

        reset({});
        setData([]);
        callFetch("projects/orders-to-plan?planType=" + planType + "&sortBy=" + sortBy + "&search=" + search, "GET", []).then((res) => {

            if (res?.data?.data) {
                setData(res.data.data);
                setTotalDuedPlan(res.data.to);
                setTotalPlan(res.data.to);

                for (let [key, value] of Object.entries(res.data.data)) {
                    if (value.icon == 'DC') {
                        setValue("dachmontag_elektromontage[" + value.id + "]", 'Dachmontage')
                    } else if (value.icon == 'AC') {
                        setValue("dachmontag_elektromontage[" + value.id + "]", 'Elektromontage')
                    }
                    if (selectedProjects.indexOf((value.id).toString()) !== -1) {
                        setValue("project_nr[" + value.id + "]", true)
                    }
                }

                setRefresh4(refresh4);
            }
        });
    }, [refresh, planType, sortBy]);

    useEffect(() => {
        callFetch("assign-order-processing/create", "GET", []).then((res) => {
            if (planType == 'stornoPlan') {
                setEmployees(res?.data?.dc_technician);
            } else if (planType == 'dcPlan' || planType == 'dcReclamationPlan') {
                setEmployees(res?.data?.dc_technician);
            } else if (planType == 'acPlan' || planType == 'acReclamationPlan') {
                setEmployees(res?.data?.ac_technician);
            } else {
                setEmployees(res?.data?.ac_technician);
            }
        });
    }, [planType]);

    useEffect(() => {
        setTotalDuedPlan(0);
        setPlanSubmitButton(false);
        if (getValues('project_nr')) {
            var selectedids = [];
            var plan_date = [];
            var plan_time = [];
            var dachmontag_elektromontage = [];
            var plan_form = [];
            var remining = 0;

            if (searching) {
                var selectProject = selectedProjects;
            } else {
                var selectProject = [];
            }

            for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('project_nr'))) {
                if (selectedrow_value) {
                    setPlanSubmitButton(true);
                    selectedids.push(selectedrow_key);
                    plan_date.push(getValues('date[' + selectedrow_key + ']'));
                    plan_time.push(getValues('time[' + selectedrow_key + ']'));
                    dachmontag_elektromontage.push(getValues('dachmontag_elektromontage[' + selectedrow_key + ']'));
                    plan_form.push(getValues('plan_form[' + selectedrow_key + ']'));

                    selectProject.push(selectedrow_key);
                    setSelectedProjects(selectProject);
                } else {
                    remining++;
                }
            }
            setTotalDuedPlan(remining);
        }
    }, [refresh2]);

    useEffect(() => {
        setTotalDuedPlan(0);
        setPlanSubmitButton(false);
        if (getValues('project_nr')) {
            var remining = 0;
            if (searching) {
                var selectProject = selectedProjects;
            } else {
                var selectProject = [];
            }
            for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('project_nr'))) {
                if (selectedrow_value) {
                    setPlanSubmitButton(true);
                    selectProject.push(selectedrow_key);
                    setSelectedProjects(selectProject);
                } else {
                    remining++;
                }
            }
            setTotalDuedPlan(remining);
        }
    }, [refresh3, refresh4]);

    const setNearestMiltarbeiter = (id) => {
        setValue("miltarbeiter[" + showPlanProjectRoute + "]", id);
        setShowPlanProjectRouteMitarbeiter(id);
        validationSuccess();
    }

    const routePlanValidation = () => {

        var absence = absenceDate.length;
        var date1 = new Date(startDate);
        var date2 = new Date(endDate);
        var Difference_In_Time = date2.getTime() - date1.getTime();
        var days = (Difference_In_Time / (1000 * 3600 * 24)) + 1;
        var canMaximumPlan = ((parseInt(days) * 12) * parseInt(routeNumber));

        var totalHours = 0;
        for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('project_nr'))) {
            if (selectedrow_value) {
                var hours = getValues('hours[' + selectedrow_key + ']');
                totalHours += parseInt(hours);
            }
        }

        if (canMaximumPlan >= totalHours) {
            return true;
        } else {
            console.log('Invalid');

            setMessageSbTitle('Die Anzahl der Routen reicht für den ausgewählten Zeitraum nicht aus.');
            setMessageSbIcon('');
            setMessageSbColor('error');
            setMessageSB(true);

            return false;
        }
    }

    const validationSuccess = () => {

        var assigned_technician_validation_check = [];
        var invalidScheduleProjects = [];

        for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('project_nr'))) {
            if (selectedrow_value) {
                var current_plan_technician_schedule = getValues('miltarbeiter[' + selectedrow_key + ']') + '_' + getValues('date[' + selectedrow_key + ']') + '_' + getValues('time[' + selectedrow_key + ']');

                if (assigned_technician_validation_check.indexOf(current_plan_technician_schedule) !== -1) {
                    invalidScheduleProjects.push(Number(selectedrow_key));
                } else {
                    assigned_technician_validation_check.push(current_plan_technician_schedule);
                }
            }
        }

        setInvalidScheduleProjectids(invalidScheduleProjects);

        if (invalidScheduleProjects.length) {
            return false;
        } else {
            return true;
        }
    }


    const redayToplanSubmit = (ready_to_plan, plan_form, id, project_id) => {
        callFetch("ready-to-plan", "POST", {
            ready_to_plan: ready_to_plan ? 'yes' : 'no',
            plan_form: plan_form,
            id: id,
            project_id: project_id,
        }).then((res) => {

            //setRefresh(refresh + 1)
        });
    }

    const onSubmit = (data) => {

    }

    useEffect(() => {
        if (props?.eventTechnicianLocation?.distance > 0) {
            setShowmap('event_route');
        }
    }, [props?.eventTechnicianLocation]);

    let timer;
    const waitTime = 2000;

    return (
        <>

            <Grid container spacing={3} pt={2}>
                <Grid item xs={12} mb={5}>


                    <div className="card">
                        <div className="card-header px-2 pb-0 pt-2">
                            <div className="float-start d-flex">
                                <h6 className="card-title m-0">
                                    Aufträge zu Planen ({totalDuedPlan}) &nbsp; &nbsp; {(totalPlan == totalDuedPlan ? 0 : totalPlan - totalDuedPlan) + ' Project Selected'}
                                </h6>

                                <div className="form-check ms-3">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="kiPlanung"
                                        onChange={(e) => {
                                            setKiPlanung(e.target.checked);
                                        }}
                                        checked={kiPlanung}
                                    />
                                    <label htmlFor="kiPlanung">KI Planung</label>
                                </div>
                            </div>

                            {!saving && (
                                <>
                                    {
                                        showRoutePlanScreen ? (
                                            <>
                                                {!planSaving ? (
                                                    <button
                                                        type="button"
                                                        className="btn btn-success float-end m-0 ms-2"
                                                        onClick={() => {
                                                            document.getElementById('routePlanSave').click();
                                                        }}
                                                    >
                                                        {t('Save')}
                                                    </button>
                                                ) : (
                                                    <button type="button" className="btn btn-disabled float-end m-0 ms-2" disabled>
                                                        {t('Saving ...')}
                                                    </button>
                                                )}

                                                <button
                                                    type="button"
                                                    className="btn btn-secondary float-end m-0"
                                                    onClick={() => {
                                                        setShowRoutePlanScreen(false);
                                                    }}
                                                >{t('Abbrechen')}</button>
                                            </>
                                        ) : planSubmitButton ? (
                                            <>
                                                <button
                                                    type="button"
                                                    className="btn btn-success float-end m-0"
                                                    onClick={() => {

                                                        if (routePlanValidation()) {
                                                            setShowRoutePlanScreen(true);
                                                        } else {
                                                            //console.log('Validation faield');
                                                        }
                                                    }}
                                                >{t('Auswahl Bestätigen')}</button>

                                                <div className="form-check float-end mx-3 pt-2">
                                                    <input
                                                        id="uncheckAll"
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        onChange={() => {
                                                            for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('project_nr'))) {
                                                                setValue("project_nr[" + selectedrow_key + "]", false);
                                                                setRefresh2(refresh2 + 1);
                                                            }
                                                        }}
                                                    />
                                                    <label htmlFor="uncheckAll">{'Abwählen'}</label>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <a
                                                    type="submit"
                                                    className="btn btn-primary float-end m-0"
                                                    data-selecttype="select_all"
                                                    onClick={(e) => {
                                                        for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('project_nr'))) {
                                                            setValue("project_nr[" + selectedrow_key + "]", true)
                                                        }
                                                        setRefresh2(refresh2 + 1);
                                                    }}
                                                >
                                                    {t('Alle Aufträge Planen')}
                                                </a>
                                            </>
                                        )}



                                    <div class="form-group float-end mx-3">
                                        <select
                                            className="form-control"
                                            onChange={(e) => {
                                                setSelectedProjects([]);
                                                setSearch('');
                                                setSearching(false);
                                                setPlanType(e.target.value);
                                                setDistanceDuration({});
                                            }}
                                            defaultValue={planType}
                                            disabled={showRoutePlanScreen ? true : false}
                                        >
                                            <option value={'all'}>All</option>

                                            {JSON.parse(Cookies.get('permissions')).indexOf("OnlyDCP") !== -1 ? (
                                                <>
                                                    <option value={'stornoPlan'}>Storno</option>
                                                    <option value={'dcPlan'}>DC Plan</option>
                                                </>
                                            ) : <></>}

                                            {JSON.parse(Cookies.get('permissions')).indexOf("OnlyACP") !== -1 ? (
                                                <option value={'acPlan'}>AC Plan</option>
                                            ) : <></>}

                                            {JSON.parse(Cookies.get('permissions')).indexOf("OnlyDCP") !== -1 ? (
                                                <option value={'dcReclamationPlan'}>DC Reclamation Plan</option>
                                            ) : <></>}

                                            {JSON.parse(Cookies.get('permissions')).indexOf("OnlyACP") !== -1 ? (
                                                <>
                                                    <option value={'acReclamationPlan'}>AC Reclamation Plan</option>
                                                    <option value={'ACFertigmeldung'}>AC Fertigmeldung</option>
                                                </>
                                            ) : <></>}
                                        </select>
                                    </div>

                                    <div class="form-group float-end mx-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t("Search...")}
                                            value={search}
                                            onChange={(e) => {

                                                setSearching(true);
                                                clearTimeout(timer);
                                                setSearch(e.target.value);

                                                timer = setTimeout(() => {
                                                    setRefresh(refresh + 1);
                                                }, waitTime);
                                            }}
                                        />
                                    </div>
                                </>
                            )}
                            {saving && (
                                <button type="submit" className="btn btn-disabled float-end m-0" disabled>
                                    {t('Saving ...')}
                                </button>
                            )}
                        </div>

                        <div className="card-body p-2" /*style={{ height: '42vh', overflowY: 'auto', overflowX: 'hidden' }}*/>
                            {/*<div className="row m-0">
                                <div className="col-12">
                                    
                                </div>
                            </div>*/}
                            <div className="row m-0">
                                <div className="col-12 col-md-3 col-lg-2 col-xl-2">
                                    <div className="form-group">
                                        <label>
                                            {t('Anzahl der Routen')}
                                        </label>
                                        <select
                                            className="form-control form-control-sm"
                                            onChange={(e) => {
                                                setRouteNumber(e.target.value);
                                            }}
                                        >
                                            <option value="">{t('Routenanzahl festlegen')}</option>
                                            {[...Array(20)].map((x, index) =>
                                                <option value={(index + 1)}>{(index + 1)}</option>
                                            )}
                                        </select>
                                        <div className="invalid-feedback">{errors.gender && errors.gender.message}</div>
                                    </div>
                                </div>

                                <div className="col-12 col-md-3 col-lg-3 col-xl-3">
                                    <div className="form-group">
                                        <label>
                                            {t('Startdatum')}
                                        </label>
                                        <input
                                            type="date"
                                            className="form-control form-control-sm mb-4 flatpickr"
                                            placeholder={t('Please select start date')}
                                            /*
                                            {...register("joining_date", {
                                                required: true,
                                            })}
                                            */
                                            onChange={(e) => {
                                                setStartDate(e.target.value);
                                            }}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.gender && errors.gender.message}</div>
                                    </div>
                                </div>

                                <div className="col-12 col-md-3 col-lg-3 col-xl-3">
                                    <div className="form-group">
                                        <label>
                                            {t('Enddatum')}
                                        </label>
                                        <input
                                            type="date"
                                            className="form-control form-control-sm mb-4 flatpickr"
                                            placeholder={t('Please select end date')}
                                            /*
                                            {...register("joining_date", {
                                                required: true,
                                            })}
                                            */
                                            min={startDate}
                                            onChange={(e) => {
                                                setEndDate(e.target.value);
                                            }}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.gender && errors.gender.message}</div>
                                    </div>
                                </div>

                                {kiPlanung ? (
                                    <>
                                        <div className="col-12 col-md-3 col-lg-2 col-xl-2 d-grid">
                                            <label>{t('Abwesenheit anlegen')}</label>
                                            <DatePicker
                                                inputClass="form-control form-control-sm mb-4 flatpick"
                                                format="DD.MM.YYYY"
                                                multiple
                                                //value={values}
                                                //onChange={(array) => setValues(array)}
                                                onChange={(e) => {
                                                    setAbsenceDate(e);
                                                }}
                                                placeholder={t("Abwesenheit anlegen")}
                                            />

                                            <div className="invalid-feedback">{errors.gender && errors.gender.message}</div>
                                        </div>

                                        <div className="col-12 col-md-3 col-lg-2 col-xl-2">
                                            <div className="form-group">
                                                <label>
                                                    {t('Sortieren nach')}
                                                </label>
                                                <select
                                                    className="form-control form-control-sm"
                                                    onChange={(e) => {
                                                        setSortBy(e.target.value);
                                                    }}
                                                    defaultValue={sortBy}
                                                    disabled={showRoutePlanScreen ? true : false}
                                                >
                                                    <option value={"Date"}>Datum</option>
                                                    <option value={"Distance"}>Distanz</option>
                                                    <option value={"Date_Distance"}>Datum & Distanz</option>
                                                </select>
                                            </div>
                                        </div>
                                    </>
                                ) : ''}
                            </div>

                            {showRoutePlanScreen ? (
                                <>
                                    <RoutePlane employees={employees} projectIds={getValues('project_nr')} hours={getValues('hours')} planType={planType} plan_form={getValues('plan_form')} startDate={startDate} endDate={endDate} routeNumber={routeNumber} /*routePlan={routePlan}*/ absenceDate={absenceDate} refresh={setRefresh} setRefresh={props?.setRefresh} setShowRoutePlanScreen={setShowRoutePlanScreen} setPlanSaving={setPlanSaving} />
                                </>
                            ) : (

                                <form style={{ height: '32vh', overflowY: 'auto', overflowX: 'hidden' }} className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                                    <Grid container spacing={2} py={0}>
                                        {data.map((item, index) => (
                                            <Grid item xs={6}>
                                                <SoftBox
                                                    key={index}
                                                    style={{
                                                        backgroundColor: (item?.lieferdatum_ready === 'yes' || item?.ready_to_plan === 'yes' ? '#B2F9D078' : '#ececec'),
                                                        //cursor: 'pointer',
                                                        height: '100%'
                                                    }}
                                                    borderRadius="md"
                                                    p={1}
                                                    mb={1}

                                                    className={(invalidScheduleProjectids.indexOf(item?.id) !== -1 ? 'invalid-schedule' : '')}

                                                    onClick={(e) => {
                                                        if (e?.target?.type != 'checkbox') {
                                                            setShowPlanProjectRoute(item?.id);
                                                            setShowPlanProjectRouteMitarbeiter(getValues("miltarbeiter[" + item?.id + "]"));
                                                            setShowPlanProjectRouteDate(getValues("date[" + item?.id + "]"));
                                                            setShowPlanProjectRouteTime(getValues("time[" + item?.id + "]"));
                                                        } else {
                                                            console.log('checkbox');
                                                            console.log(e);
                                                        }

                                                        setPlanForm(item?.plan_form);

                                                        validationSuccess();
                                                        //setShowmap('');
                                                    }}
                                                >

                                                    <input
                                                        type="hidden"
                                                        {...register("plan_form[" + item.id + "]", {
                                                            required: true,
                                                        })}
                                                        value={item?.plan_form}
                                                    />
                                                    <table className="table mb-0" key={index}>
                                                        <tr>
                                                            <td style={{ width: '50px', textAlign: 'left', verticalAlign: 'top' }}>
                                                                <div className="form-check form-check-inline m-0">
                                                                    <input
                                                                        className="form-check-input ms-0"
                                                                        type="checkbox"
                                                                        {...register("project_nr[" + item?.id + "]", {
                                                                            required: false,
                                                                            onChange: (e) => {
                                                                                if (e.target.checked == false) {
                                                                                    var selectProject = selectedProjects;
                                                                                    selectProject = selectProject.filter(val => val !== ((item?.id).toString()));
                                                                                    setSelectedProjects(selectProject);
                                                                                }

                                                                                setRefresh3(refresh3 + 1);
                                                                                validationSuccess();
                                                                                if (search) {
                                                                                    setTimeout(() => {
                                                                                        //setSearch('');
                                                                                        // setRefresh(refresh + 1);
                                                                                    }, 2000)
                                                                                }
                                                                            }
                                                                        })}
                                                                    />
                                                                    <label className="form-check-label"></label>
                                                                </div>
                                                            </td>

                                                            <td style={{ lineHeight: '12px' }}>
                                                                <div className="d-flex justify-content-between ps-0">
                                                                    <div className="d-flex">
                                                                        <SoftTypography style={{ fontSize: '0.875rem', width: '122px' }} className="p-0" variant="button">
                                                                            {t(item?.title)}.: {item?.plan_form == 'Reclamation' ? (
                                                                                <a onClick={() => {
                                                                                    setViewRelamation({
                                                                                        id: item?.id,
                                                                                        projectId: item?.project_nr
                                                                                    })
                                                                                }}
                                                                                    data-bs-toggle="modal"
                                                                                    data-bs-target="#reclimationView"
                                                                                    className="text-primary"
                                                                                >
                                                                                    {'R-' + item.id}
                                                                                </a>
                                                                            ) : (
                                                                                <>
                                                                                    <NavLink to={'/projects/' + (item?.plan_form == 'ACFertigmeldung' ? item.project_id : item.id)} className="text-primary">{(item?.plan_form == 'ACFertigmeldung' ? item.project_id : item.id)}</NavLink>
                                                                                </>
                                                                            )}
                                                                        </SoftTypography>

                                                                        <div className="d-flex mt-1 ms-1">
                                                                            {item?.gerust_notwendig == 'JA' ? <div style={{ backgroundColor: '#0048B1', color: '#ffffff', borderRadius: '3px', width: '22px', height: '16px', fontSize: '10px', textAlign: 'center', fontWeight: '700', lineHeight: '16px' }}>{'GR '}</div> : ''}
                                                                            {item?.frealeatung_isolierung == 'JA' ? <div style={{ backgroundColor: '#CB0C9F', marginLeft: '5px', color: '#ffffff', borderRadius: '3px', width: '22px', height: '16px', fontSize: '10px', textAlign: 'center', fontWeight: '700', lineHeight: '16px' }}>{'FR '}</div> : ''}
                                                                            {item?.grabungsarbeiten == 'Yes' ? <div style={{ backgroundColor: '#2EB67D', marginLeft: '5px', color: '#ffffff', borderRadius: '3px', width: '22px', height: '16px', fontSize: '10px', textAlign: 'center', fontWeight: '700', lineHeight: '16px' }}>{'GA'}</div> : ''}
                                                                        </div>
                                                                    </div>

                                                                    <SoftBox
                                                                        display="grid"
                                                                        justifyContent="center"
                                                                        alignItems="center"
                                                                        color="white"
                                                                        width="2.2rem"
                                                                        height="2.2rem"
                                                                        shadow="md"
                                                                        borderRadius="lg"
                                                                        variant="gradient"
                                                                        style={{
                                                                            backgroundColor: (item?.icon == 'AC' || item?.icon == 'ZT' ? '#48BB78' : '#E53E3E'),
                                                                        }}
                                                                        ms={'auto'}
                                                                    >
                                                                        <Typography style={{
                                                                            fontSize: '14px'
                                                                        }}>{item?.icon}</Typography>
                                                                    </SoftBox>
                                                                </div>

                                                                <SoftTypography className="p-0" style={{ fontSize: '0.75rem' }} variant="caption"><span style={{
                                                                    width: '95px',
                                                                    display: 'inline-block'
                                                                }}>{t('Name')}</span>: <strong>{item?.name + ', ' + item?.street + ' ' + item?.nr + ', ' + item?.plz_ort + (item?.phone && item?.phone != 'null' ? ', ' + item?.phone : '')}</strong></SoftTypography><br /><br />

                                                                {item?.plan_form == 'Reclamation' ? (
                                                                    <>
                                                                        {item?.products ? JSON.parse(item?.products).map((product, product_index) => (
                                                                            <>
                                                                                <SoftTypography key={product_index} className="p-0" style={{ fontSize: '0.75rem' }} variant="caption">
                                                                                    <span >{t(product?.id ? product?.id.split('-')?.[0] : '')}</span>: <strong>{product?.quantity + ' Stck. | ' + product?.title}</strong>
                                                                                </SoftTypography>
                                                                                <br />
                                                                            </>
                                                                        )) : ''}

                                                                        <SoftTypography className="p-0" style={{ fontSize: '0.75rem' }} variant="caption"><span style={{
                                                                            width: '95px',
                                                                            display: 'inline-block'
                                                                        }}>{t('Dachtyp')}</span>: <strong>{item?.dachtyp}</strong></SoftTypography><br />
                                                                        <SoftTypography className="p-0" style={{ fontSize: '0.75rem' }} variant="caption"><span style={{
                                                                            width: '95px',
                                                                            display: 'inline-block'
                                                                        }}>{t('Vorh. Techniker')}</span>: <strong>{item?.prevelektromontage?.employee?.name ? item?.prevelektromontage?.employee?.name : item?.prevdachmontag?.employee?.name ? item?.prevdachmontag?.employee?.name : '--'}</strong></SoftTypography><br />
                                                                        <SoftTypography className="p-0" style={{ fontSize: '0.75rem' }} variant="caption"><span style={{
                                                                            width: '95px',
                                                                            display: 'inline-block'
                                                                        }}>{t('Project')}</span>: <strong>{item?.project_nr}</strong></SoftTypography><br /><br />

                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {item?.newQuation === 'yes' ? (
                                                                            <>
                                                                                {item?.productItems && item?.productItems != 'null' ? JSON.parse(item?.productItems)?.items?.map((productItem, j) => (
                                                                                    <>
                                                                                        {productItem?.type == 'bundle' && productItem.item.id ? (
                                                                                            <>
                                                                                                <p
                                                                                                    style={{
                                                                                                        fontSize: '0.875rem',
                                                                                                        fontWeight: 'bold',
                                                                                                        color: '#2D3748'
                                                                                                    }}
                                                                                                    className="m-0 p-0 mt-0"
                                                                                                >{productItem.item?.label}</p>
                                                                                                {productItem.item?.items ? productItem.item?.items.map((item, item_index) => (
                                                                                                    <>
                                                                                                        <SoftTypography className="p-0" style={{ fontSize: '0.75rem' }} variant="caption">
                                                                                                            <Tooltip title={item[item?.type]?.lieferdatum ? ('PO-' + item[item?.type]?.po_number + ': ' + item[item?.type]?.lieferdatum) : ''} placement="bottom">
                                                                                                                <i className="fa fa-truck me-1" style={{ marginLeft: '-16px', color: (item[item?.type]?.lieferdatum ? '#48BB78' : '#344767') }}></i>
                                                                                                            </Tooltip>
                                                                                                            {item[item?.type]?.identity}
                                                                                                            <span style={{
                                                                                                                width: '95px',
                                                                                                                display: 'inline-block'
                                                                                                            }}>{t(item?.type)}</span>: <strong>{item[item?.type]?.name + ' - ' + item?.number + ' Stück'}</strong>
                                                                                                        </SoftTypography>
                                                                                                        <br />
                                                                                                    </>
                                                                                                )) : ''}
                                                                                            </>
                                                                                        ) : productItem?.type == 'product' ? (
                                                                                            <>
                                                                                                <SoftTypography className="p-0" style={{ fontSize: '0.75rem' }} variant="caption">
                                                                                                    <Tooltip title={productItem?.item?.lieferdatum ? ('PO-' + productItem?.item?.po_number + ': ' + productItem?.item?.lieferdatum) : ''} placement="bottom">
                                                                                                        <i className="fa fa-truck me-1" style={{ marginLeft: '-16px', color: (productItem?.item?.lieferdatum ? '#48BB78' : '#344767') }}></i>
                                                                                                    </Tooltip>
                                                                                                    <span style={{
                                                                                                        width: '95px',
                                                                                                        display: 'inline-block'
                                                                                                    }}>{t(productItem?.item?.value.includes('Pr-') ? 'Zusatsprodukte'
                                                                                                        : productItem?.item?.value.includes('MDL-') ? 'Modul'
                                                                                                            : productItem?.item?.value.includes('SPC-') ? 'Speicher'
                                                                                                                : productItem?.item?.value.includes('WR-') ? 'Wechselrichter'
                                                                                                                    : '')}</span>: <strong>{t(productItem?.item?.value)} {productItem?.item?.label + ' - ' + productItem?.quantity + ' Stück'}</strong>
                                                                                                </SoftTypography>
                                                                                                <br />
                                                                                            </>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </>
                                                                                )) : ''}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <SoftTypography className="p-0" style={{ fontSize: '0.75rem' }} variant="caption"><span >{t('Modul')}</span>: <strong>{item?.anzahl_module + ' Stck. | ' + (item?.module_eingeben == 'no' ? item?.module_short_title : item?.custom_module_short_title)}</strong></SoftTypography><br />
                                                                                <SoftTypography className="p-0" style={{ fontSize: '0.75rem' }} variant="caption"><span >{t('Speicher')}</span>: <strong>{item?.speicher_eingeben == 'no' ? item?.speicher_name : item?.custom_speicher_name}</strong></SoftTypography><br />
                                                                            </>
                                                                        )}

                                                                        <SoftTypography className="p-0" style={{ fontSize: '0.75rem' }} variant="caption"><span style={{
                                                                            width: '95px',
                                                                            display: 'inline-block'
                                                                        }}>{t('Dachtyp')}</span>: <strong>{item?.dachtyp}</strong></SoftTypography><br />

                                                                        {item?.plan_form == 'ACFertigmeldung' ? (
                                                                            <>
                                                                                <SoftTypography className="p-0" style={{ fontSize: '0.75rem' }} variant="caption"><span style={{
                                                                                    width: '95px',
                                                                                    display: 'inline-block'
                                                                                }}>{t('Vorh. Techniker')}</span>: <strong>{item?.prevelektromontage?.employee?.name ? item?.prevelektromontage?.employee?.name : item?.prevdachmontag?.employee?.name ? item?.prevdachmontag?.employee?.name : '--'}</strong></SoftTypography><br /><br />
                                                                            </>
                                                                        ) : <br />}
                                                                    </>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <div>
                                                        <table className="table mb-0" key={index}>
                                                            <tr>
                                                                <td style={{ width: '50px' }}>
                                                                </td>
                                                                <td>
                                                                    <SoftTypography
                                                                        className="p-0 float-start"
                                                                        variant="caption"
                                                                        style={{
                                                                            color: '#0048B1',
                                                                            fontWeight: 'bold',
                                                                            fontSize: '0.75rem',
                                                                            marginTop: '5px'
                                                                        }}
                                                                    >Wunschtermin: {/*item.assembly_date ? item.assembly_date : '--'*/}{item.assembly_date ? dateFormat(item?.assembly_date, "dd.mm.yyyy") : '--'}
                                                                    </SoftTypography>

                                                                    <select
                                                                        className="form-control form-control-sm w-35 float-start ms-2"
                                                                        defaultValue={item?.plan_hours ? item?.plan_hours : item?.plan_form == 'Reclamation' ? '3' : item?.anzahl_module <= 22 ? '12' : '24'}
                                                                        {...register("hours[" + item?.id + "]", {
                                                                            required: false,
                                                                        })}
                                                                        style={{
                                                                            height: '22px',
                                                                            paddingBottom: '0px',
                                                                            paddingTop: '0px',
                                                                            fontSize: '11px'
                                                                        }}
                                                                        onChange={(e) => {
                                                                            console.log(e.target.value)

                                                                            var formData = {};
                                                                            formData.related = item?.plan_form;
                                                                            formData.plan_type = item?.icon;
                                                                            formData.related_id = item?.id;
                                                                            formData.hours = e.target.value;

                                                                            callFetch("plan-cache", "POST", formData, setError).then((res) => {

                                                                            });
                                                                        }}
                                                                    >
                                                                        <option value={3}>3 Stunden</option>
                                                                        <option value={6}>6 Stunden</option>
                                                                        <option value={9}>9 Stunden</option>
                                                                        <option value={12}>1 Tage</option>
                                                                        <option value={24}>2 Tage</option>
                                                                    </select>

                                                                    {item?.icon == 'ZT' ? (
                                                                        <SoftTypography
                                                                            className="p-0 float-start"
                                                                            variant="caption"
                                                                            style={{
                                                                                color: '#0048B1',
                                                                                fontWeight: 'bold',
                                                                                fontSize: '11px',
                                                                                marginTop: '5px'
                                                                            }}
                                                                        >Zählertermin: {item.acZDate ? dateFormat(item?.acZDate, "dd.mm.yyyy") + ' ' + (item.acZTime ? item.acZTime + ' Uhr' : '') : '--'}{/*item.assembly_date ? dateFormat(item?.assembly_date, "dd.mm.yyyy") : '--'*/}
                                                                        </SoftTypography>
                                                                    ) : ''}
                                                                </td>
                                                                <td>
                                                                    <div className="mt-1">
                                                                        <div class="form-check form-switch float-end">
                                                                            <input
                                                                                class="form-check-input"
                                                                                type="checkbox"
                                                                                onChange={(e) => {
                                                                                    redayToplanSubmit(e.target.checked, item?.plan_form, item?.id, (item?.plan_form == 'Reclamation' ? item?.project_nr : item?.plan_form == 'ACFertigmeldung' ? item.project_id : item.id));
                                                                                    var updateData = data;
                                                                                    updateData[index].ready_to_plan = e.target.checked ? 'yes' : 'no';
                                                                                    setData(updateData);
                                                                                }}
                                                                                defaultChecked={item?.ready_to_plan === 'yes' ? true : false}
                                                                            />
                                                                        </div>
                                                                        <label class="form-check-label float-end me-5">Bereit zur Planung</label>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </SoftBox>
                                            </Grid>
                                        ))}
                                    </Grid>

                                </form>

                            )}
                        </div>
                    </div>
                </Grid >
                {/*
                <Grid item xs={4}>
                    <div className="card">
                        <div className="card-body p-2">
                            {showmap == 'event_route' ? (
                                <>
                                    <ProjectPlannedTechnicianLocation height="400px" data={props?.eventTechnicianLocation} />
                                </>
                            ) : (
                                <>
                                    <FindNearestUsersByProject
                                        //miltarbeiter={showPlanProjectRouteMitarbeiter}
                                        //date={showPlanProjectRouteDate}
                                        //time={showPlanProjectRouteTime}
                                        //setNearestMiltarbeiter={setNearestMiltarbeiter}
                                        //projectId={showPlanProjectRoute}
                                        // dachmontag_elektromontage={planType == 'dcPlan' ? 'Dachmontage' : 'Elektromontage'}
                                        detalsDisplay={false}
                                    //refreshNearestMitarbeiter={refreshNearestMitarbeiter}
                                    //setDirectionDistance={setDirectionDistance}
                                    //planForm={planForm}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </Grid>
                */}
            </Grid >


            <div
                className="modal fade"
                id="reclimationView"
                tabindex="-1"
                role="dialog"
                aria-labelledby="reclimationViewLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-xl p-0" role="document">
                    <div className="modal-content">
                        <div className="modal-body p-0">
                            <ReclamationShow viewRelamation={viewRelamation} fullwidth={true} />
                        </div>
                    </div>
                </div>
            </div>

            {renderMessageSB}
        </>
    );
}

export default OrdersToPlan;
