import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie'; 
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography" 
import Tooltip from "@mui/material/Tooltip";
import SoftButton from "components/SoftButton";
import Checkbox from "@mui/material/Checkbox";
import Select from 'react-select';
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import SoftAvatar from "components/SoftAvatar";
import SoftSnackbar from "components/SoftSnackbar";
function Create() {
    const { t } = useTranslation();
    const [filderData, setFilderData] = useState([]);
    const [users, setUsers] = useState([]); 
    const [refresh, setRefresh] = useState(0); 
    const [moduleTyp, setModuleType] = useState(''); 
    const [saving, setSaving] = useState(false); 
    let params = useParams(); 

    const {
        register,
        handleSubmit,
        reset,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm(); 


    useEffect(() => {  
        callFetch("get-module-info"+'/'+params.id, "GET", []).then((res) => {
            // setData(res.data); 
            if(!res?.data) return;
            setValue('modulanzahl', res.data.anzahl_module);
            setValue('simulation_kwh', res.data.ges_dachflache); 
            var ges = ((405 * Number(res.data.anzahl_module)) / 1000);
            setValue('gesamtleistung', ges); 
            setValue('eigenverbrauch', res.data.eigenverbrauch); 
            setValue('kwh_preis', res.data.kwh_preis); 
            setModuleType(res?.moduleWR?.name); 
            setValue('modultyp', res?.moduleWR?.name); 
            setValue('stromkosten', res.data.stromkosten); 
            setValue('einspeiseverguetung_preis', res.data.einspeiseverguetung_preis); 
            setValue('notstrom_kapazitat', res.data.notstrom_kapazitat); 
            setValue('modulwirkungsgrad', res.data.modulwirkungsgrad); 
            setValue('einspeiseverguetung_preis_percent', res.data.einspeiseverguetung_preis_percent); 
            setValue('kaufpreisEigenverbrauch', res.data.kaufpreisEigenverbrauch); 
            setValue('kaufpreisEigenverbrauch_percent', res.data.kaufpreisEigenverbrauch_percent); 
        });
         
    }, [refresh]);


    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <SoftSnackbar
            color="success"
            icon="check"
            title="Success"
            content="Successfully updated"
            dateTime="Just Now"
            open={successSB}
            onClose={closeSuccessSB}
            close={closeSuccessSB}
            bgWhite
        />
    );


    //Form Submit
    const onSubmit = (formData) => {
        setSaving(true); 
        formData.id = params.id;
        callFetch("update-quation-profit-clc-data", "POST", formData, setError).then((res) => {
           setSaving(false);
           openSuccessSB();
           document.getElementById('cls-btn').click();
           setRefresh(refresh+1);
        });
    };


    return <>
         <div class="modal fade" id="staticBackdropddd" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
            
                    <div class="modal-body pt-4 pb-3">
                        <button type="button" id="cls-btn" data-bs-dismiss="modal" aria-label="Close" className=" lose btn btn-icon-only btn-rounded btn-outline-dark mb-0 me-3 btn-sm d-flex align-items-center justify-content-center" style={{ fontSize: '12px', position: 'absolute', right: '0px' }}><i class="fa-sharp fa-solid fa-times icon icon-shape icon-sm  text-center d-flex align-items-center justify-content-center"></i></button>
                        <SoftTypography variant="button" fontSize="18px" fontWeight="bold" style={{color: '#2D3748'}}> Datenerfassung für die Wirtschaftlichkeitsberechnung </SoftTypography><br/>
                        <SoftTypography variant="button" fontSize="14px" fontWeight="regular" style={{color: '#A0AEC0'}}> Erstellen sie eıne Aufgabe  </SoftTypography><br/>
                        <div className="mt-4"></div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                {/* Modultyp */}
                                <div className="col-sm-6">
                                    <div className="form-group d-flex align-items-center">
                                        <label className="me-2 mb-0" style={{ width: "180px", textAlign: "left" }}>Modultyp</label>
                                        <select className="form-control" {...register("modultyp")}>
                                            {/* <option value=""></option> */}
                                            <option value={moduleTyp}>{moduleTyp}</option> 
                                        </select>
                                    </div>
                                </div>

                                {/* Modulanzahl */}
                                <div className="col-sm-6">
                                    <div className="form-group d-flex align-items-center">
                                        <label className="me-2 mb-0" style={{ width: "180px", textAlign: "left" }}>Modulanzahl</label>
                                        <input className="form-control" {...register('modulanzahl')}/>
                                    </div>
                                </div>

                                {/* Gesamtleistung */}
                                <div className="col-sm-6">
                                    <div className="form-group d-flex align-items-center">
                                        <label className="me-2 mb-0" style={{ width: "180px", textAlign: "left" }}>Gesamtleistung</label>
                                        <input type="text" className="form-control" {...register("gesamtleistung")} />
                                    </div>
                                </div>

                                {/* In Simulation kWh/kwp */}
                                <div className="col-sm-6">
                                    <div className="form-group d-flex align-items-center">
                                        <label className="me-2 mb-0" style={{ width: "180px", textAlign: "left" }}>In Simulation kWh/kwp</label>
                                        <input type="text" className="form-control" {...register("simulation_kwh")} />
                                    </div>
                                </div>

                                {/* Einspeisevergütung Preis / kWh */}
                                <div className="col-sm-6">
                                    <div className="form-group d-flex align-items-center">
                                        <label className="me-2 mb-0" style={{ width: "180px", textAlign: "left" }}>Einspeisevergütung Preis / kWh</label>
                                        {/* <div className="input-group"> */}
                                            <input type="text" className="form-control" style={{width: '103px'}} placeholder="Amount e.g 3300"  {...register("einspeiseverguetung_preis")} /> <label className="mt-2">€</label>
                                            <select className="form-control" style={{width: '70px', marginLeft: '8px'}} {...register("einspeiseverguetung_preis_percent")}> 
                                                <option >--</option>
                                                <option value={10}>10%</option>
                                                <option value={20}>20%</option>
                                                <option value={30}>30%</option>
                                                <option value={40}>40%</option>
                                                <option value={50}>50%</option>
                                                <option value={60}>60%</option>
                                                <option value={70}>70%</option>
                                                <option value={80}>80%</option>
                                                <option value={90}>90%</option>
                                                <option value={100}>100%</option>
                                            </select> <label className="mt-2">Abdeckung</label>
                                            {/* <span className="input-group-text">0,38 €/kWh</span> */}
                                        {/* </div> */}
                                    </div>
                                </div>

                                {/* Kaufpreis für Eigenverbrauch / kWh */}
                                <div className="col-sm-6">
                                    <div className="form-group d-flex align-items-center">
                                        <label className="me-2 mb-0" style={{ width: "180px", textAlign: "left" }}>Kaufpreis für Eigenverbrauch / kWh</label>
                                        <input type="text" className="form-control" placeholder="Amount e.g 3300" style={{width: '103px'}} {...register("kaufpreisEigenverbrauch")} /> <label className="mt-2">€</label>
                                        <select className="form-control" style={{width: '70px', marginLeft: '8px'}} {...register("kaufpreisEigenverbrauch_percent")}>
                                            <option>--</option>
                                            <option value={10}>10%</option>
                                            <option value={20}>20%</option>
                                            <option value={30}>30%</option>
                                            <option value={40}>40%</option>
                                            <option value={50}>50%</option>
                                            <option value={60}>60%</option>
                                            <option value={70}>70%</option>
                                            <option value={80}>80%</option>
                                            <option value={90}>90%</option>
                                            <option value={100}>100%</option>
                                        </select> <label className="mt-2">Abdeckung</label>
                                    </div>
                                </div>

                                {/* Stromverbrauch in kWh */}
                                <div className="col-sm-6">
                                    <div className="form-group d-flex align-items-center">
                                        <label className="me-2 mb-0" style={{ width: "180px", textAlign: "left" }}>Stromverbrauch in kWh</label>
                                        <input type="text" className="form-control" {...register("eigenverbrauch")} />
                                    </div>
                                </div>

                                {/* Stromkosten / Jahr */}
                                <div className="col-sm-6">
                                    <div className="form-group d-flex align-items-center">
                                        <label className="me-2 mb-0" style={{ width: "180px", textAlign: "left" }}>Stromkosten / Jahr</label>
                                        <input type="text" className="form-control" {...register("stromkosten")} />
                                    </div>
                                </div>

                                {/* Aktueller Strompreis des Kunden €/kWh */}
                                <div className="col-sm-6">
                                    <div className="form-group d-flex align-items-center">
                                        <label className="me-2 mb-0" style={{ width: "180px", textAlign: "left" }}>Aktueller Strompreis des Kunden €/kWh</label>
                                        <input type="text" className="form-control" {...register("kwh_preis")} />
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="form-group d-flex align-items-center">
                                        <label className="me-2 mb-0" style={{ width: "180px", textAlign: "left" }}>Notstrom Kapazitat</label> 
                                        <select className="form-control" {...register("notstrom_kapazitat")}>
                                            <option>--</option>
                                            <option value={0.1}>10%</option>
                                            <option value={0.2}>20%</option>
                                            <option value={0.3}>30%</option>
                                            <option value={0.4}>40%</option>
                                            <option value={0.5}>50%</option>
                                            <option value={0.6}>60%</option>
                                            <option value={0.7}>70%</option>
                                            <option value={0.8}>80%</option>
                                            <option value={0.9}>90%</option>
                                            <option value={1}>100%</option>
                                        </select>  
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group d-flex align-items-center">
                                        <label className="me-2 mb-0" style={{ width: "180px", textAlign: "left" }}>Modulwirkungsgrad </label> 
                                        <select className="form-control" {...register("modulwirkungsgrad")}>
                                            <option>--</option>
                                            <option value={0.1}>10%</option>
                                            <option value={0.2}>20%</option>
                                            <option value={0.3}>30%</option>
                                            <option value={0.4}>40%</option>
                                            <option value={0.5}>50%</option>
                                            <option value={0.6}>60%</option>
                                            <option value={0.7}>70%</option>
                                            <option value={0.8}>80%</option>
                                            <option value={0.9}>90%</option>
                                            <option value={1}>100%</option>
                                        </select>  
                                    </div>
                                </div>
                            </div>
                            {saving ? <button className="btn btn-secondary" disabled style={{float: 'right', width: '110px'}}>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </button> : <button className="btn to-do-modal-btn">
                                {t('Erstellen')}
                            </button>}
                        </form>

                        
                    </div>
                
                </div>
            </div>
        </div>
        {renderSuccessSB}
    </>
}

export default Create;
