import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid'; 
import { Button } from '@mui/material';
import { Navigate, useParams } from "react-router-dom";
import callFetch from "../../../../../../helpers/callFetch";
const initialRows = [
  { "id": 1, "month": "January", "verbrauch": "15 kWh", "produktion": "59 kWh", "eigenverbrauch": "43 kWh", "uberschussproduktion": "83 kWh" },
  { "id": 2, "month": "February", "verbrauch": "14 kWh", "produktion": "24 kWh", "eigenverbrauch": "39 kWh", "uberschussproduktion": "45 kWh" },
  { "id": 3, "month": "March", "verbrauch": "45 kWh", "produktion": "46 kWh", "eigenverbrauch": "64 kWh", "uberschussproduktion": "44 kWh" },
  { "id": 4, "month": "April", "verbrauch": "39 kWh", "produktion": "22 kWh", "eigenverbrauch": "31 kWh", "uberschussproduktion": "83 kWh" },
  { "id": 5, "month": "May", "verbrauch": "47 kWh", "produktion": "27 kWh", "eigenverbrauch": "51 kWh", "uberschussproduktion": "47 kWh" },
  { "id": 6, "month": "June", "verbrauch": "25 kWh", "produktion": "35 kWh", "eigenverbrauch": "50 kWh", "uberschussproduktion": "55 kWh" },
  { "id": 7, "month": "July", "verbrauch": "17 kWh", "produktion": "66 kWh", "eigenverbrauch": "41 kWh", "uberschussproduktion": "88 kWh" },
  { "id": 8, "month": "August", "verbrauch": "28 kWh", "produktion": "57 kWh", "eigenverbrauch": "75 kWh", "uberschussproduktion": "74 kWh" },
  { "id": 9, "month": "September", "verbrauch": "30 kWh", "produktion": "70 kWh", "eigenverbrauch": "74 kWh", "uberschussproduktion": "76 kWh" },
  { "id": 10, "month": "October", "verbrauch": "37 kWh", "produktion": "34 kWh", "eigenverbrauch": "42 kWh", "uberschussproduktion": "80 kWh" },
  { "id": 11, "month": "November", "verbrauch": "23 kWh", "produktion": "67 kWh", "eigenverbrauch": "62 kWh", "uberschussproduktion": "51 kWh" },
  { "id": 12, "month": "December", "verbrauch": "31 kWh", "produktion": "45 kWh", "eigenverbrauch": "55 kWh", "uberschussproduktion": "62 kWh" }
];

const columns = [
  { field: 'month', headerName: 'Monate', flex: 1 },
  { field: 'verbrauch', headerName: 'Verbrauch kWh', flex: 1, editable: true },
  { field: 'produktion', headerName: 'Produktion kWh', flex: 1, editable: true },
  { field: 'eigenverbrauch', headerName: 'Eigenverbrauch kWh', flex: 1, editable: true },
  { field: 'uberschussproduktion', headerName: 'Überschussproduktion kWh', flex: 1, editable: true },
];

function TableEstimatedProductionConsumption({onUpdate}) {
  const [rows, setRows] = useState(initialRows);
  let params = useParams();

  const handleProcessRowUpdate = (newRow) => {
    const updatedRows = rows.map((row) => (row.id === newRow.id ? newRow : row));
    const formData = new FormData();
    formData.data = JSON.stringify(updatedRows);
    formData.project_id = params.id;
    callFetch("estimated-production-consumption-update", "POST", formData).then((res) => { 
        var resData = res?.data; 
        setRows(res?.data); 
        console.log("Table updated!");
        onUpdate();
    });  
    setRows(updatedRows);
    console.log(updatedRows);
    return newRow;
  };

  useEffect(() => { 
    callFetch("estimated-production-consumption/" + params.id, "GET", []).then((res) => { 
        var resData = res?.data; 
        // onUpdate();
        // console.log("Table updated!");
        setRows(res?.data);
    }); 
}, []);

  return (
    <div style={{ height: 750, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        processRowUpdate={handleProcessRowUpdate} // Handles updates
        experimentalFeatures={{ newEditingApi: true }}  // Enables editing
        pageSize={9}
        autoHeight
        pagination={false}
        disableSelectionOnClick
        rowsPerPageOptions={[]}
        hideFooterPagination 
        hideFooterSelectedRowCount 
      />
    </div>
  );
}
 

// const TableEstimatedProductionConsumption = () => {
//   const [tableData, setTableData] = useState([]);

//   useEffect(() => {
//     const data = months.map((month) => ({
//       month,
//       verbrauch: getRandomNumber(),
//       produktion: getRandomNumber(),
//       eigenverbrauch: getRandomNumber(),
//       uberschussproduktion: getRandomNumber(),
//     }));
//     setTableData(data);
//   }, []);

//   const handleInputChange = (index, field, value) => {
//     // Remove 'kWh' from the value before storing it in the state
//     const numericValue = value.replace(/ kWh/g, '');
//     const updatedData = [...tableData];
//     updatedData[index][field] = numericValue;
//     setTableData(updatedData);
//   };

//   return (
//     <>
//       <table className="table table-bordered table-sm" style={{ borderCollapse: 'collapse', width: '100%' }}>
//         <thead>
//           <tr style={{ backgroundColor: '#E5E5E5' }}>
//             <th style={{ border: '1px solid #828282' }}>Monate</th>
//             <th style={{ border: '1px solid #828282' }}>Verbrauch (kWh)</th>
//             <th style={{ border: '1px solid #828282' }}>Produktion (kWh)</th>
//             <th style={{ border: '1px solid #828282' }}>Eigenverbrauch (kWh)</th>
//             <th style={{ border: '1px solid #828282' }}>Überschussproduktion (kWh)</th>
//           </tr>
//         </thead>
//         <tbody>
//           {tableData.map((row, index) => (
//             <tr key={index}>
//               <td style={{ border: '1px solid #828282', padding: '5px' }}>{row.month}</td>
//               <td style={{ border: '1px solid #828282' }}>
//                 <input
//                   type="text"
//                   value={row.verbrauch + ' kWh'}
//                   onChange={(e) => handleInputChange(index, 'verbrauch', e.target.value)}
//                   style={{
//                     border: 'none',
//                     width: '100%',
//                     outline: 'none',
//                     padding: '5px'
//                   }}
//                 />
//               </td>
//               <td style={{ border: '1px solid #828282' }}>
//                 <input
//                   type="text"
//                   value={row.produktion + ' kWh'}
//                   onChange={(e) => handleInputChange(index, 'produktion', e.target.value)}
//                   style={{
//                     border: 'none',
//                     width: '100%',
//                     outline: 'none',
//                     padding: '5px'
//                   }}
//                 />
//               </td>
//               <td style={{ border: '1px solid #828282' }}>
//                 <input
//                   type="text"
//                   value={row.eigenverbrauch + ' kWh'}
//                   onChange={(e) => handleInputChange(index, 'eigenverbrauch', e.target.value)}
//                   style={{
//                     border: 'none',
//                     width: '100%',
//                     outline: 'none',
//                     padding: '5px'
//                   }}
//                 />
//               </td>
//               <td style={{ border: '1px solid #828282' }}>
//                 <input
//                   type="text"
//                   value={row.uberschussproduktion + ' kWh'}
//                   onChange={(e) => handleInputChange(index, 'uberschussproduktion', e.target.value)}
//                   style={{
//                     border: 'none',
//                     width: '100%',
//                     outline: 'none',
//                     padding: '5px'
//                   }}
//                 />
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </>
//   );
// };

export default TableEstimatedProductionConsumption;
