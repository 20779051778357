import { useState, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useTranslation } from 'react-i18next';
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Order from './Order';
import breakpoints from "assets/theme/base/breakpoints";
import Cookies from 'js-cookie';
import { callFetch } from "../../../../helpers/callFetch";

function getSteps() {

    // var tabs = ["1. Quotation", "2. Order", "3. Digital Sign"];
    var tabs = ["1. Quotation"];

    if (JSON.parse(Cookies.get('permissions')).indexOf("OPR") !== -1) { 
        tabs.push('2. Digital Sign');
    }
    return tabs;
}

function getStepContent(stepIndex) {
    switch (stepIndex) {
        case 0:
            return (<></>);
        case 1:
            return <Order />;
        default:
            return null;
    }
}


function Index() {
    const { t } = useTranslation();
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();
    const isLastStep = activeStep === steps.length - 1;
    const [activeTab, setActiveTab] = useState('auftrag');
    const handleNext = () => setActiveStep(activeStep + 1);
    const handleBack = () => setActiveStep(activeStep - 1);
    let params = useParams(); 
    const [tabsOrientation, setTabsOrientation] = useState("horizontal");
    const [tabValue, setTabValue] = useState(0);
    const [data, setData] = useState(0);

    useEffect(() => {
        // A function that sets the orientation state of the tabs.
        function handleTabsOrientation() {
        return window.innerWidth < breakpoints.values.sm
            ? setTabsOrientation("vertical")
            : setTabsOrientation("horizontal");
        }

        /** 
         The event listener that's calling the handleTabsOrientation function when resizing the window.
        */
        window.addEventListener("resize", handleTabsOrientation);

        // Call the handleTabsOrientation function to set the state with the initial value.
        handleTabsOrientation();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleTabsOrientation);

       

    }, [tabsOrientation]);

    const handleSetTabValue = (event, newValue) => {
        console.log(newValue);
        setTabValue(newValue);
        if(newValue == 1){
            callFetch("get-module-info"+'/'+params.id, "GET", []).then((res) => {
                // if(data?.einspeiseverguetung_preis || data?.einspeiseverguetung_preis > 0 && 
                //     data?.kaufpreisEigenverbrauch || data?.kaufpreisEigenverbrauch > 0){
                //     document.getElementById('wirtschaftlichkeitsberechnung').click();
                // }else{
                //     console.log('stcsdf');
                    document.getElementById('wirtschaftlichkeitsberechnung').click();
                // } 
            }); 
        }else{
            document.getElementById('distribution').click();
        }
    };


    return (
        <>
            <SoftBox>
                <Grid container>
                <Grid mt={1} mb={3} item xs={12} sm={8} lg={4}>
                    <AppBar position="static">
                    <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                        <Tab label="Angebot" />
                        <Tab label="Wirtschaftlichkeitsberechnung" /> 
                    </Tabs>
                    </AppBar>
                </Grid>
                </Grid> 
            </SoftBox>
            {/* {steps.length > 1 ? (
                <SoftBox mt={1} mb={1}>
                    <Grid justifyContent="center">
                        <Grid item xs={12} lg={8}>
                            <Stepper activeStep={activeStep} alternativeLabel className="my-3">
                                {steps.map((label) => {
                                    if (label === '1. Quotation') {
                                        return <Step key={label}>
                                            <StepLabel>1. {t('Angebote')}</StepLabel>
                                        </Step>
                                    }else{
                                        return <Step key={label}>
                                            <StepLabel>2. {t('Digital Sign')}</StepLabel>
                                        </Step>
                                    }
                                }
                                )}
                            </Stepper>
                        </Grid>
                    </Grid>
                </SoftBox>
            ) : ''} */}
        </>
    );
}

export default Index;
