import React, { useEffect, useState, useRef } from 'react'
import SoftTypography from 'components/SoftTypography';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

const PositionsModal = ({
    setShowTwo,
    productIndex,
    productItems,
    bundles,
    products,
    getSpeicherTypesFromBundles,
    getWattsFromBundles,
    setProductItems,
    setproductItemsRefresh,
    productItemsRefresh,
    //setMenuIsOpen,
    mitarbeiterkennungOptional,
    setMitarbeiterkennung,
    withTypeSearch,
    withWattSearch,
    withOrWithoutSpeicher,
}) => {

    const { t } = useTranslation();
    const [search, setSearch] = useState("");
    const categories = [{ name: "Alle", value: '' }, { name: "AC Produkt", value: 'ac' }, { name: "DC Produkt", value: 'dc' }, { name: "Dienstleistung", value: 'Dienstleistung' }];
    const handleClose = () => setShowTwo(false);
    const modalRef = useRef();

    useEffect(() => {
        // Function to handle click outside the modal
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setShowTwo(false); // Set showTwo to false if clicked outside
            }
        };

        // Add event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setShowTwo]);

    const bundleOrProductChoose = (option) => {
        var update = productItems;
        update.items[productIndex].price = (option?.price ? option?.price : 0);
        update.items[productIndex].item = option;

        //console.log(option)

        setProductItems(update);
        setproductItemsRefresh(productItemsRefresh + 1);

        //setMenuIsOpen(false);

        if (mitarbeiterkennungOptional === false) {

            //console.log('Mitarbeiterkennung open')
            setMitarbeiterkennung(true);


            type LoginFormResult = {
                mitarbeiterkennung: string
            }

            let mitarbeiterkennungInput: HTMLInputElement
            let show_hide_mitarbeiterkennung: HTMLDivElement

            //Sales person price
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-primary me-2',
                    cancelButton: 'btn btn-danger',
                    input: "form-control form-control-sm mt-4",
                },
                buttonsStyling: false
            });

            return swalWithBootstrapButtons.fire({
                title: t("Mitarbeiterkennung"),
                html: `
    <div class="input-group">
        <input type="password" id="mitarbeiterkennung" class="form-control" pattern="[0-9]*" inputmode="numeric">
        <div class="input-group-append">
            <span id="show_hide_mitarbeiterkennung" style="left: -35px; top: 10px; position: relative; z-index: 5; cursor: pointer;">
                <i class="fa fa-eye-slash " aria-hidden="true"></i>
            </span>
        </div>
    </div>
`,
                showCancelButton: true,
                confirmButtonText: t('Save'),
                cancelButtonText: t('Cancel'),
                focusConfirm: false,
                didOpen: () => {
                    const popup = Swal.getPopup();
                    mitarbeiterkennungInput = popup.querySelector('#mitarbeiterkennung')
                    show_hide_mitarbeiterkennung = popup.querySelector('#show_hide_mitarbeiterkennung')
                    mitarbeiterkennungInput.onkeyup = (event) => event.key === 'Enter' && Swal.clickConfirm()
                    show_hide_mitarbeiterkennung.addEventListener("click", () => {

                        if (mitarbeiterkennungInput.getAttribute('type') == 'password') {
                            mitarbeiterkennungInput.setAttribute('type', 'number')
                        } else {
                            mitarbeiterkennungInput.setAttribute('type', 'password')
                        }
                    });
                },
                preConfirm: () => {
                    const mitarbeiterkennung = mitarbeiterkennungInput.value
                    if (!mitarbeiterkennung || !/^[0-9]+$/.test(mitarbeiterkennung)) {
                        Swal.showValidationMessage(`gültige Kennung eingeben`)
                    } else {
                        var update = productItems;
                        update.items[productIndex].price = Number(update.items[productIndex].price) + Number(mitarbeiterkennung ? mitarbeiterkennung : 0);
                        update.items[productIndex].sales_person_price = Number(mitarbeiterkennung);
                        setProductItems(update);
                        setproductItemsRefresh(productItemsRefresh + 4);
                    }
                    return { mitarbeiterkennung }
                },
            }).then((result) => {
                if (result.isConfirmed) {

                }

                //console.log('Mitarbeiterkennung close')
                setMitarbeiterkennung(false);
            });
        }
    }

    return <>
        <div className="modal-dialog" ref={modalRef} style={{ background: '#ffffff' }}>
            <div className="modal-content">
                <div className="modal-body p-4">
                    <div><SoftTypography variant="h6" fontWeight="bold">Produktselektion</SoftTypography> <span onClick={() => setShowTwo(false)} className='fa fa-close' style={{ position: 'absolute', right: '25px', top: '30px', cursor: 'pointer' }}></span></div>
                    <div className='form-group'>
                        <br />
                        <label>Hersteller</label>
                        <select
                            className='form-control'
                            value={productItems?.items[productIndex].lieferanten_id}
                            onChange={(e) => {
                                var update = productItems;
                                update.items[productIndex].lieferanten_id = parseInt(e.target.value);

                                console.log(update.items[productIndex].lieferanten_id)

                                if (productItems?.items[productIndex].type == 'bundle') {
                                    update.items[productIndex].searchWatt = '';
                                    update.items[productIndex].searchType = '';

                                    update.items[productIndex].price = 0;
                                    update.items[productIndex].item = [];
                                }

                                setProductItems(update);
                                setproductItemsRefresh(productItemsRefresh + 1);
                            }}
                        >
                            <option value={''}>Please select hersteller</option>
                            {productItems.items[productIndex].type === 'bundle' ? (
                                <>
                                    {bundles.map((hersteller) => (
                                        <option value={hersteller?.id}>{hersteller?.label}</option>
                                    ))}
                                </>
                            ) : productItems.items[productIndex].type === 'product' ? (
                                <>
                                    {products.map((hersteller) => (
                                        <option value={hersteller?.id}>{hersteller?.label}</option>
                                    ))}
                                </>
                            ) : ''}
                        </select>
                    </div>

                    {productItems.items[productIndex].type === 'product' ? (
                        <div className='form-group'>
                            <label>Produktkategorie</label>
                            <div style={{ overflowX: 'auto'}}>
                                <div style={{ display: "flex", gap: "10px" }}>
                                    <button
                                        onClick={() => {
                                            var update = productItems;
                                            update.items[productIndex].product_form = (update.items[productIndex].product_form === 'zusatzprodukte' ? '' : 'zusatzprodukte');
                                            setProductItems(update);
                                            setproductItemsRefresh(productItemsRefresh + 1);
                                        }}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "8px",
                                            padding: "5px 20px",
                                            border: "none",
                                            borderRadius: "8px",
                                            cursor: "pointer",
                                            backgroundColor: productItems?.items[productIndex]?.product_form == 'zusatzprodukte' ? "#007bff" : "#e0e0e0",
                                            color: productItems?.items[productIndex]?.product_form == 'zusatzprodukte' ? "#fff" : "#000",
                                            fontWeight: productItems?.items[productIndex]?.product_form == 'zusatzprodukte' ? "bold" : "normal",
                                            position: "relative",
                                        }}
                                    >
                                        {productItems?.items[productIndex]?.product_form == 'zusatzprodukte' && (
                                            <span className='fa fa-check'></span>
                                        )}
                                        {'Zusatzprodukte'}
                                    </button>

                                    <button
                                        onClick={() => {
                                            var update = productItems;
                                            update.items[productIndex].product_form = (update.items[productIndex].product_form === 'module' ? '' : 'module');
                                            update.items[productIndex].product_type = '';
                                            setProductItems(update);
                                            setproductItemsRefresh(productItemsRefresh + 1);
                                        }}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "8px",
                                            padding: "5px 20px",
                                            border: "none",
                                            borderRadius: "8px",
                                            cursor: "pointer",
                                            backgroundColor: productItems?.items[productIndex]?.product_form == 'module' ? "#007bff" : "#e0e0e0",
                                            color: productItems?.items[productIndex]?.product_form == 'module' ? "#fff" : "#000",
                                            fontWeight: productItems?.items[productIndex]?.product_form == 'module' ? "bold" : "normal",
                                            position: "relative",
                                        }}
                                    >
                                        {productItems?.items[productIndex]?.product_form == 'module' && (
                                            <span className='fa fa-check'></span>
                                        )}
                                        {'Module'}
                                    </button>

                                    <button
                                        onClick={() => {
                                            var update = productItems;
                                            update.items[productIndex].product_form = (update.items[productIndex].product_form === 'speicher' ? '' : 'speicher');
                                            update.items[productIndex].product_type = '';
                                            setProductItems(update);
                                            setproductItemsRefresh(productItemsRefresh + 1);
                                        }}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "8px",
                                            padding: "5px 20px",
                                            border: "none",
                                            borderRadius: "8px",
                                            cursor: "pointer",
                                            backgroundColor: productItems?.items[productIndex]?.product_form == 'speicher' ? "#007bff" : "#e0e0e0",
                                            color: productItems?.items[productIndex]?.product_form == 'speicher' ? "#fff" : "#000",
                                            fontWeight: productItems?.items[productIndex]?.product_form == 'speicher' ? "bold" : "normal",
                                            position: "relative",
                                        }}
                                    >
                                        {productItems?.items[productIndex]?.product_form == 'speicher' && (
                                            <span className='fa fa-check'></span>
                                        )}
                                        {'Speicher'}
                                    </button>

                                    <button
                                        onClick={() => {
                                            var update = productItems;
                                            update.items[productIndex].product_form = (update.items[productIndex].product_form === 'wechselrichter' ? '' : 'wechselrichter');
                                            update.items[productIndex].product_type = '';
                                            setProductItems(update);
                                            setproductItemsRefresh(productItemsRefresh + 1);
                                        }}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "8px",
                                            padding: "5px 20px",
                                            border: "none",
                                            borderRadius: "8px",
                                            cursor: "pointer",
                                            backgroundColor: productItems?.items[productIndex]?.product_form == 'wechselrichter' ? "#007bff" : "#e0e0e0",
                                            color: productItems?.items[productIndex]?.product_form == 'wechselrichter' ? "#fff" : "#000",
                                            fontWeight: productItems?.items[productIndex]?.product_form == 'wechselrichter' ? "bold" : "normal",
                                            position: "relative",
                                        }}
                                    >
                                        {productItems?.items[productIndex]?.product_form == 'wechselrichter' && (
                                            <span className='fa fa-check'></span>
                                        )}
                                        {'Wechselrichter'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : ''}

                    {productItems?.items?.[productIndex]?.type === 'bundle' ? (
                        <>
                            <div className='form-group'>
                                <label>Speicher vorh.</label>
                                <div style={{ overflowX: 'auto' }}>
                                    <div style={{ display: "flex", gap: "10px" }}>
                                        <button
                                            onClick={() => {
                                                var update = productItems;
                                                update.items[productIndex].with_speicher = (update.items[productIndex].with_speicher === 'all' ? '' : 'all');
                                                setProductItems(update);
                                                setproductItemsRefresh(productItemsRefresh + 1);
                                            }}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "8px",
                                                padding: "5px 20px",
                                                border: "none",
                                                borderRadius: "8px",
                                                cursor: "pointer",
                                                backgroundColor: productItems?.items[productIndex]?.with_speicher === 'all' ? "#007bff" : "#f0f0f0",
                                                color: productItems?.items[productIndex]?.with_speicher === 'all' ? "#fff" : "#000",
                                                fontWeight: productItems?.items[productIndex]?.with_speicher === 'all' ? "bold" : "normal",
                                            }}
                                        >
                                            {productItems?.items[productIndex]?.with_speicher === 'all' && (
                                                <span className='fa fa-check'></span>
                                            )}
                                            {t('All')}
                                        </button>

                                        <button
                                            onClick={() => {
                                                var update = productItems;
                                                update.items[productIndex].with_speicher = (update.items[productIndex].with_speicher == true ? '' : true);

                                                setProductItems(update);
                                                setproductItemsRefresh(productItemsRefresh + 1);
                                            }}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "8px",
                                                padding: "5px 20px",
                                                border: "none",
                                                borderRadius: "8px",
                                                cursor: "pointer",
                                                backgroundColor: productItems?.items[productIndex]?.with_speicher === true ? "#007bff" : "#f0f0f0",
                                                color: productItems?.items[productIndex]?.with_speicher === true ? "#fff" : "#000",
                                                fontWeight: productItems?.items[productIndex]?.with_speicher === true ? "bold" : "normal",
                                            }}
                                        >
                                            {productItems?.items[productIndex]?.with_speicher === true && (
                                                <span className='fa fa-check'></span>
                                            )}
                                            {t('With Speicher')}
                                        </button>

                                        <button
                                            onClick={() => {
                                                var update = productItems;
                                                update.items[productIndex].with_speicher = (update.items[productIndex].with_speicher === false ? '' : false);
                                                update.items[productIndex].searchWatt = '';
                                                update.items[productIndex].searchType = '';

                                                update.items[productIndex].price = 0;
                                                update.items[productIndex].item = [];

                                                setProductItems(update);
                                                setproductItemsRefresh(productItemsRefresh + 1);
                                            }}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "8px",
                                                padding: "5px 20px",
                                                border: "none",
                                                borderRadius: "8px",
                                                cursor: "pointer",
                                                backgroundColor: productItems?.items[productIndex]?.with_speicher === false ? "#007bff" : "#f0f0f0",
                                                color: productItems?.items[productIndex]?.with_speicher === false ? "#fff" : "#000",
                                                fontWeight: productItems?.items[productIndex]?.with_speicher === false ? "bold" : "normal",
                                            }}
                                        >
                                            {productItems?.items[productIndex]?.with_speicher === false && (
                                                <span className='fa fa-check'></span>
                                            )}
                                            {t('Without Speicher')}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className='form-group'>
                                <label>Speicher Typ</label>
                                <div style={{ overflowX: 'auto' }}>
                                    <div style={{ display: "flex", gap: "10px" }}>
                                        {getSpeicherTypesFromBundles(productItems?.items[productIndex].lieferanten_id).map((bundle_speicher_types, bundle_speicher_typesIndex) => (
                                            <button
                                                key={bundle_speicher_typesIndex}
                                                onClick={() => {
                                                    var update = productItems;
                                                    update.items[productIndex].searchType = (update.items[productIndex].searchType == bundle_speicher_types?.id ? '' : bundle_speicher_types?.id);
                                                    update.items[productIndex].searchWatt = '';

                                                    update.items[productIndex].price = 0;
                                                    update.items[productIndex].item = [];

                                                    setProductItems(update);
                                                    setproductItemsRefresh(productItemsRefresh + 1);
                                                }}
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "8px",
                                                    padding: "5px 20px",
                                                    border: "none",
                                                    borderRadius: "8px",
                                                    cursor: "pointer",
                                                    backgroundColor: productItems?.items[productIndex]?.searchType == bundle_speicher_types?.id ? "#007bff" : "#f0f0f0",
                                                    color: productItems?.items[productIndex]?.searchType == bundle_speicher_types?.id ? "#fff" : "#000",
                                                    fontWeight: productItems?.items[productIndex]?.searchType == bundle_speicher_types?.id ? "bold" : "normal",
                                                }}
                                            >
                                                {productItems?.items[productIndex]?.searchType == bundle_speicher_types?.id && (
                                                    <span className='fa fa-check'></span>
                                                )}
                                                {t(bundle_speicher_types?.name)}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className='form-group'>
                                <label>Speicher kWh</label>
                                <div style={{ overflowX: 'auto' }}>
                                    <div style={{ display: "flex", gap: "10px" }}>
                                        {getWattsFromBundles(productItems?.items[productIndex].lieferanten_id, productItems?.items[productIndex]?.searchType).map((bundle_watt, bundle_wattIndex) => (
                                            <button
                                                key={bundle_wattIndex}
                                                onClick={() => {
                                                    var update = productItems;
                                                    update.items[productIndex].searchWatt = (update.items[productIndex].searchWatt == bundle_watt ? '' : bundle_watt);

                                                    update.items[productIndex].price = 0;
                                                    update.items[productIndex].item = [];

                                                    setProductItems(update);
                                                    setproductItemsRefresh(productItemsRefresh + 1);
                                                }}
                                                style={{
                                                    padding: "5px 16px",
                                                    border: "none",
                                                    borderRadius: "8px",
                                                    cursor: "pointer",
                                                    backgroundColor: productItems?.items[productIndex]?.searchWatt == bundle_watt ? "#007bff" : "#e0e0e0",
                                                    color: productItems?.items[productIndex]?.searchWatt == bundle_watt ? "#fff" : "#000",
                                                    fontWeight: productItems?.items[productIndex]?.searchWatt == bundle_watt ? "bold" : "normal",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "8px",
                                                }}
                                            >
                                                {productItems?.items[productIndex]?.searchWatt == bundle_watt && (
                                                    <span className='fa fa-check'></span>
                                                )}
                                                {t(bundle_watt + ' kWh')}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className='form-group' style={{ minHeight: '69px' }}>
                            {productItems?.items[productIndex]?.product_form == 'zusatzprodukte' ? (
                                <>
                                    <label>Kategorie</label>
                                    <div style={{ overflowX: 'auto' }}>
                                        <div style={{ display: "flex", gap: "10px" }}>
                                            {categories.map((category, categoryIndex) => (
                                                <button
                                                    key={category?.value}
                                                    onClick={() => {
                                                        var update = productItems;
                                                        update.items[productIndex].product_type = (update.items[productIndex].product_type == category?.value ? '' : category?.value);
                                                        setProductItems(update);
                                                        setproductItemsRefresh(productItemsRefresh + 1);
                                                    }}
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "8px",
                                                        padding: "5px 20px",
                                                        border: "none",
                                                        borderRadius: "8px",
                                                        cursor: "pointer",
                                                        backgroundColor: productItems.items[productIndex].product_type === category?.value ? "#007bff" : "#e0e0e0",
                                                        color: productItems.items[productIndex].product_type === category?.value ? "#fff" : "#000",
                                                        fontWeight: productItems.items[productIndex].product_type === category?.value ? "bold" : "normal",
                                                        position: "relative",
                                                    }}
                                                >
                                                    {productItems.items[productIndex].product_type === category?.value && (
                                                        <span className='fa fa-check'></span>
                                                    )}
                                                    {category?.name}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            ) : ''}
                        </div>
                    )}

                    <hr />

                    <div style={{ width: "100%", margin: "0 auto", fontFamily: "Arial, sans-serif" }}>
                        {/* Search Input */}
                        <input
                            type="text"
                            placeholder={productItems.items[productIndex].type === 'bundle' ? "Suchen sie ihr passenden Bundle" : "Suchen sie ihr passenden Produkt"}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            style={{
                                width: "100%",
                                padding: "10px",
                                borderRadius: "8px",
                                border: "1px solid #ccc",
                                marginBottom: "20px"
                            }}
                        />

                        <div className='inner-main' style={{ height: '320px', marginBottom: '35px' }}>
                            {productItems?.items?.[productIndex]?.type === 'bundle' ? (
                                <>
                                    {(isNaN(productItems?.items[productIndex].lieferanten_id)
                                        ? bundles // If lieferanten_id is NaN, use all bundles
                                        : bundles.filter((group) => group?.id === (
                                            productItems?.items[productIndex].lieferanten_id
                                                ? productItems?.items[productIndex].lieferanten_id
                                                : bundles[0]?.id
                                        ))
                                    )
                                        .flatMap((group) => group?.options)
                                        .filter((bundle) => {
                                            // Filter based on the search value
                                            if (!search) return true;
                                            return (
                                                bundle?.label.toLowerCase().includes(search.toLowerCase()) ||
                                                bundle?.items.some((bundleItem) =>
                                                    (bundleItem?.[bundleItem?.type]?.name || "").toLowerCase().includes(search.toLowerCase()) ||
                                                    (bundleItem?.number || "").toString().includes(search)
                                                )
                                            );
                                        })
                                        .map((bundle) => (
                                            (productItems?.items[productIndex].type === 'bundle' ? (
                                                withTypeSearch(productItems?.items[productIndex]?.searchType, bundle) &&
                                                withWattSearch(productItems?.items[productIndex]?.searchWatt, bundle) &&
                                                withOrWithoutSpeicher(productItems?.items[productIndex]?.with_speicher, bundle)
                                            ) : true) && bundle?.product_from === productItems?.items[productIndex]?.product_form ? (
                                                <div
                                                    key={bundle.id}
                                                    onClick={() => bundleOrProductChoose(bundle)}
                                                    className={productItems?.items[productIndex]?.item?.value == bundle.id ? 'positionsModal-bundle-product-active' : 'positionsModal-bundle-product'}
                                                >
                                                    <input
                                                        type="radio"
                                                        checked={productItems?.items[productIndex]?.item?.value == bundle.id}
                                                        onChange={() => bundleOrProductChoose(bundle)}
                                                        style={{
                                                            accentColor: "#007bff",
                                                            cursor: "pointer",
                                                            marginTop: "3px"
                                                        }}
                                                    />
                                                    <div>
                                                        <p style={{ margin: "0", fontWeight: "bold" }}>{bundle?.label}</p>
                                                        <ul style={{ padding: "0", margin: "0px 0 0 0", listStyleType: "none", color: "#666" }}>
                                                            {bundle?.items.map((bundleItem, index) => (
                                                                <li key={index} style={{ marginTop: "0px", fontSize: '0.75rem' }}>
                                                                    {bundleItem?.[bundleItem?.type]?.name} - {bundleItem?.number} Stück
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            ) : null
                                        ))}
                                </>
                            ) : productItems.items[productIndex].type === 'product' ? (
                                <>
                                    {(isNaN(productItems?.items[productIndex].lieferanten_id)
                                        ? products // If lieferanten_id is NaN, use all products
                                        : products.filter((group) => group?.id === (
                                            productItems?.items[productIndex].lieferanten_id
                                                ? productItems?.items[productIndex].lieferanten_id
                                                : products[0]?.id
                                        ))
                                    )
                                        .flatMap((group) => group?.options)
                                        .filter((product) => {
                                            // Filter based on the search value
                                            if (!search) return true;
                                            return (
                                                product?.value.toLowerCase().includes(search.toLowerCase()) ||
                                                product?.label.toLowerCase().includes(search.toLowerCase())
                                            );
                                        })
                                        .map((product) => (
                                            (productItems?.items[productIndex].type === 'bundle' ? (
                                                withTypeSearch(productItems?.items[productIndex]?.product_type, product) &&
                                                withWattSearch(productItems?.items[productIndex]?.searchWatt, product) &&
                                                withOrWithoutSpeicher(productItems?.items[productIndex]?.with_speicher, product)
                                            ) : true)
                                                && (productItems?.items[productIndex]?.product_form === '' ? true : (product?.product_from === productItems?.items[productIndex]?.product_form))
                                                && (productItems?.items[productIndex]?.product_type === '' ? true : (product?.type == productItems?.items[productIndex]?.product_type))
                                                ? (
                                                    <div
                                                        key={product.value}
                                                        onClick={() => bundleOrProductChoose(product)}
                                                        className={productItems?.items[productIndex]?.item?.value == product.value ? 'positionsModal-bundle-product-active' : 'positionsModal-bundle-product'}
                                                    >
                                                        <input
                                                            type="radio"
                                                            checked={productItems?.items[productIndex]?.item?.value == product.value}
                                                            onChange={() => bundleOrProductChoose(product)}
                                                            style={{
                                                                accentColor: "#007bff",
                                                                cursor: "pointer",
                                                                marginTop: "3px"
                                                            }}
                                                        />
                                                        <div>
                                                            <p style={{ margin: "0", fontWeight: "bold" }}>{product?.value} - {product?.label}</p>
                                                            <ul style={{ padding: "0", margin: "0px 0 0 0", listStyleType: "none", color: "#666" }}>
                                                                <li style={{ marginTop: "0px", fontSize: '0.75rem' }}>
                                                                    {product?.value?.includes('MDL-') ? (product?.description ? JSON.parse(product?.description)?.['description'] : '') : product?.description}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                ) : null
                                        ))}
                                </>
                            ) : ''}
                        </div>
                    </div>
                    <button
                        className='btn btn-primary'
                        style={{ position: 'absolute', bottom: '0px' }}
                        onClick={() => {
                            if (document.getElementById('editdstBtn')) {
                                setShowTwo(false);
                                document.getElementById('editdstBtn').click();
                            }
                        }}> {t('Save')}</button>
                </div>
            </div>
        </div>
    </>
}

export default PositionsModal