import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import OpenInvoiceIndexTable from './OpenInvoiceIndexTable'
import { Divider, Icon, Menu, MenuItem } from '@mui/material'
import SoftTypography from 'components/SoftTypography'
import SoftButton from 'components/SoftButton'
import SoftBox from 'components/SoftBox'
import callFetch from "../../../helpers/callFetch";
const OpenInvoiceIndex = () => {
  const { t } = useTranslation()

  const [menu, setMenu] = useState(null);
  const [status, setStatus] = useState('');
  const [filterData, setFilterData] = useState([]);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = (statu = '') => {
    setMenu(null);
    setStatus(statu);
  };

  useEffect(() => {
    callFetch("invoice-filter-data", "GET", []).then((res) => {
      setFilterData(res?.data)
    });
  }, []);

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={() => { closeMenu('') }}
      keepMounted
    >
      <MenuItem onClick={() => { closeMenu('') }}>{t('All')}</MenuItem>
      {filterData.map((value, index) => (
        <MenuItem onClick={() => { closeMenu(value?.after_action) }}>
          {value?.percentage}% {value?.after_action === 'DC_Abnahme_erfolgreich' ? 'DC Rechnung Erstellen' : 'Speicher Rechnung Erstellen'}
        </MenuItem>
      ))}
      {/*
      <MenuItem onClick={()=> { closeMenu('30% Speicher Rechnung Erstellen') }}>30% Speicher Rechnung Erstellen</MenuItem>
      <MenuItem onClick={()=> { closeMenu('70% DC Rechnung Erstellen') }}>70% DC Rechnung Erstellen</MenuItem>
      */}
      <MenuItem onClick={() => { closeMenu('Rechnungen erstellt') }}>Rechnungen erstellt</MenuItem>
    </Menu>
  );
  return (
    <>
      <div className="d-flex justify-content-end">
        <div className="mb-2">
          <SoftButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
            {
              status === 'DC_Abnahme_erfolgreich' ? 'DC Rechnung Erstellen'
                : status === 'Zahleingang_100_or_AC_Freigeben' ? 'Speicher Rechnung Erstellen'
                  //: status === 'After_AC_Abnahme_Succesfull' ? 'Speicher Rechnung Erstellen'
                  : status ? status
                    : 'GESAMT'}&nbsp;
            <Icon>keyboard_arrow_down</Icon>
          </SoftButton>
          {renderMenu}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card mb-4">
            <div className="card-header pb-0">
              <h6>{t('Alle offenen Rechnungen')}</h6>
            </div>
            <div className="card-body px-0 pt-0 pb-2">
              <OpenInvoiceIndexTable status={status} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OpenInvoiceIndex